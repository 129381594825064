import { FC, useEffect, useRef } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'

import { ICaptchaInfo } from 'containers/FormBuilder'

import { SRecaptcha } from './styled.index'

// TODO BPD-7074 Привести в порядок запуск сервиса и env
const { REACT_APP_RECAPTCHA_KEY } = process.env

type TRecaptcha = {
  onChange?: (token: string | null) => void
  resetCaptcha: {
    captchaInfo: ICaptchaInfo
    setCaptchaInfo: (value: ICaptchaInfo) => void
  }
}

const RecaptchaV2: FC<TRecaptcha> = ({
  onChange,
  resetCaptcha: {
    captchaInfo: { errorCount, maxErrorCount },
    setCaptchaInfo,
  },
}) => {
  const recaptchaRef = useRef<ReCAPTCHA>(null)
  useEffect(() => {
    if (onChange && errorCount === maxErrorCount) {
      recaptchaRef?.current?.reset()
      onChange('')
      setCaptchaInfo({ errorCount: 0, maxErrorCount })
    }
  }, [errorCount, maxErrorCount, setCaptchaInfo])

  return (
    <SRecaptcha>
      <ReCAPTCHA hl="ru" sitekey={REACT_APP_RECAPTCHA_KEY} onChange={onChange} ref={recaptchaRef} />
    </SRecaptcha>
  )
}

export default RecaptchaV2
