import { FORM_ERROR } from 'final-form'

export const REDIRECT_URL = 'externalRedirect'

export const dummyUser = { firstName: '―', lastName: '―' }

export const ACTION_SUBMIT = 'submit'

export const commonError = { [FORM_ERROR]: 'Произошла неизвестная ошибка' }

export const SHAREHOLDER_TYPE_LEGAL = 'ЮЛ'

export const SHAREHOLDER_TYPE_INDIVIDUAL = 'ФЛ'

export const SBBOL_TOKEN_COOKIE_NAME = 'sbbol-token'

export const SBBOL_120_SEC_PRODUCT = 'premier'

export const SBBOL_PREMIUM = 'ds_premium'

export const SBBOL_115FZ = 'course_115fz'

export const SBBOL_LMS = 'LMS'

export const COOP_OAUTH_BTNS_LIST = ['sberBusinessId', 'demoAccess']

export const OTR_TYPE_LS_KEY = 'otr_type'

export const COURSE_LS_KEY = 'course'
