import { useMutation } from '@apollo/client'
import { loader } from 'graphql.macro'

import { sendEmailToTemplateSlug, sendEmailToTemplateSlugVariables } from './types'

const SEND_EMAIL_TO_TEMPLATE_SLUG = loader('src/gql/mutations/sendEmailToTemplateSlug.gql')

const useSendEmailToTemplateSlug = (subject: string, templateSlug: string) => {
  const [sendEmail] = useMutation<sendEmailToTemplateSlug, sendEmailToTemplateSlugVariables>(
    SEND_EMAIL_TO_TEMPLATE_SLUG
  )

  return (email: string) => {
    sendEmail({
      variables: { email, subject, templateSlug },
    })
  }
}

export default useSendEmailToTemplateSlug
