import { ComponentType, FC, ReactElement, SVGProps, useEffect, useState } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import {
  SEyeBlockIcon,
  SEyeIcon,
  SFieldWrapper,
  SFieldWrapperComponent,
  SFieldWrapperComponentLink,
} from './styled.index'

interface ILink {
  pathname: string
  text: string
  search: string
  className?: string
}

const FieldLink: FC<ILink> = ({ pathname, text, search, className }) => (
  <SFieldWrapperComponentLink to={{ pathname, search }} className={className}>
    {text}
  </SFieldWrapperComponentLink>
)

type TBindedLink = ({ search, className }: Pick<ILink, 'search' | 'className'>) => ReactElement<ILink>
type TParamsToBind = (pathname: string, text: string) => TBindedLink

const bindParamsToLink: TParamsToBind =
  (pathname, text) =>
  ({ search, className }) =>
    <FieldLink pathname={pathname} text={text} search={search} className={className} />

type State = {
  component: FC<SVGProps<SVGSVGElement>>
  type?: string
}

// for icons, etc.
type TPropsWithState = {
  default?: State
  active?: State
  activeTimeout?: number | null
  className?: string
}

// for links
type TDefaultProps = {
  component?: TBindedLink
}

type TConfig = {
  [key: string]: TDefaultProps & TPropsWithState
}

const config: TConfig = {
  password: {
    default: {
      component: SEyeBlockIcon,
      type: 'password',
    },
    active: {
      component: SEyeIcon,
      type: 'text',
    },
    activeTimeout: Number(process.env.REACT_APP_PASSWORD_ACTIVE_TIMEOUT),
  },
  loginDefault: {
    component: bindParamsToLink('/login-by-phone', 'Телефон'),
  },
  loginByPhone: {
    component: bindParamsToLink('/login', 'Почта'),
  },
  regByEmail: {
    component: bindParamsToLink('/registration/phone', 'Телефон'),
  },
  regByPhone: {
    component: bindParamsToLink('/registration/email', 'Почта'),
  },
  resetByEmail: {
    component: bindParamsToLink('/password-reset-request/phone', 'Телефон'),
  },
  resetByPhone: {
    component: bindParamsToLink('/password-reset-request/email', 'Почта'),
  },
}

interface IFieldWrapper {
  type: keyof typeof config
  withState?: boolean
}

// TODO: add unit tests
const FieldWrapper: FC<IFieldWrapper & RouteComponentProps> = ({ children, location, type, withState = false }) => {
  const [state, setState] = useState<'default' | 'active'>('default')
  const { search } = location
  const params = config[type]

  let renderComponent = null
  let inputType = 'text'

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>
    if (withState && state === 'active' && params.activeTimeout) {
      timer = setTimeout(() => setState('default'), params.activeTimeout)
    }

    return () => {
      clearTimeout(timer)
    }
  }, [state, params, withState])

  if (withState) {
    const { component: WrapperComponent, type: inputTypeByState = 'text' } = params[state] as State
    const toggleStatus = () => {
      setState((prevState) => (prevState === 'default' ? 'active' : 'default'))
    }

    inputType = inputTypeByState

    renderComponent = <WrapperComponent onClick={toggleStatus} width="24" height="37" />
  } else {
    const { component: WrapperComponent } = params as TDefaultProps & {
      component: TBindedLink
    }
    renderComponent = <WrapperComponent search={search} />
  }

  return (
    <SFieldWrapper>
      {typeof children === 'function' ? children({ type: inputType }) : children}

      <SFieldWrapperComponent>{renderComponent}</SFieldWrapperComponent>
    </SFieldWrapper>
  )
}

const FieldWrapperWithRouter = withRouter(FieldWrapper) as ComponentType<IFieldWrapper>

export const bindFieldWrapperParams =
  ({ type, withState }: IFieldWrapper): FC =>
  ({ children = null }) =>
    (
      <FieldWrapperWithRouter type={type} withState={withState}>
        {children}
      </FieldWrapperWithRouter>
    )

export default FieldWrapperWithRouter
