import find from 'lodash/find'

import { helpers } from 'eco-api'

import { PUBLISH_PD } from 'configs/agreements'
import { updateUserData } from 'utils/auth'

const { upsertContact } = helpers.getresponse

export const requestToGTR = async (userData, customFields, campaignId) => {
  if (userData?.email) {
    await upsertContact(userData, { customFields, campaignId })
  }
}

export const sendNewUserToGTR = async (user, options = {}) => {
  const { agreements, isFirstLogin, email, firstName, lastName, publicName, channelId } = user
  const publishPD = find(agreements, { type: PUBLISH_PD }) || {}
  const { isFastRegChannel, campaignId, customOptions } = options

  /* *
   * Свойство isFirstLogin введено фронтом понимания нужно ли записывать пользователя в getresponse,
   * для регистрации по каналам с подтверждением, мы не можем сразу отправить его в GTR (например, SLF).
   * Данного свойства нет у старых пользователей, поэтому для них данная логика не применима
   * предполагается, что они уже есть в getresponse.
   * CHANNEL_ID - введено для определения канала регистрации пользователя и передачи его в GTR
   * */
  if (isFirstLogin || isFastRegChannel) {
    if (isFirstLogin) await updateUserData({ ...user, isFirstLogin: false })

    if (publishPD.agree) {
      const userData = {
        email,
        firstName,
        lastName,
        publicName,
      }
      const customFields = {
        dispatchagree: publishPD.agree,
        source: channelId,
        ...customOptions,
      }

      await requestToGTR(userData, customFields, campaignId)
    }
  }
}
