import GoogleTagManager from 'react-gtm-module'

interface ISendDataLayer {
  event?: string
  eventCategory?: string
  eventAction?: string
  eventLabel?: string
  userId?: number | string
}

export const sendDataLayer = (dataLayer: ISendDataLayer): void => {
  if (process.env.NODE_ENV === 'production') {
    GoogleTagManager.dataLayer({
      dataLayer,
    })
  }
}

export const dataLayerHandler = ({ event, eventCategory, eventAction, userId }: ISendDataLayer): void => {
  const dataLayer: ISendDataLayer = {
    event,
    eventCategory,
    eventAction,
    eventLabel: 'null',
  }

  if (userId) {
    dataLayer.userId = userId
  }

  sendDataLayer(dataLayer)
}
