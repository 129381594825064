import { FC } from 'react'

import {
  SProfileChangeDivider,
  SProfileChangeIcon,
  SProfileChangeSubtitle,
  SProfileChangeText,
  SProfileChangeTitle,
  SProfileChangeWrapper,
} from './styled.index'

const defaultTitle = 'Регистрация прошла успешно!'
const defaultDesc = 'Чтобы начать пользоваться сервисом введите необходимые данные:'

const config = {
  email: {
    title: defaultTitle,
    notify: 'Пароль для вашего аккаунта мы выслали на вашу почту.',
    description: defaultDesc,
  },
  phone: {
    title: defaultTitle,
    notify: 'Пароль для вашего аккаунта мы выслали на ваш телефон.',
    description: defaultDesc,
  },
  login: {
    title: 'Авторизация прошла успешо',
    notify: '',
    description: defaultDesc,
  },
}

export interface IStatusInfo {
  type?: keyof typeof config
}

const StatusInfo: FC<IStatusInfo> = ({ type = 'email' }) => {
  const { title, notify, description } = config[type]

  return (
    <SProfileChangeWrapper>
      <SProfileChangeIcon />
      <SProfileChangeTitle level={2}>{title}</SProfileChangeTitle>
      <SProfileChangeSubtitle level={1}>{notify}</SProfileChangeSubtitle>
      <SProfileChangeDivider />
      <SProfileChangeText>{description}</SProfileChangeText>
    </SProfileChangeWrapper>
  )
}

export default StatusInfo
