import { NavLink } from 'react-router-dom'
import { media, Paragraph, Title } from '@dasreda/components'
import { CheckboxCircle } from '@dasreda/icons'
import styled, { css } from 'styled-components'

import { ISHeaderWrapperProps } from './types'

export const SHeaderWrapper = styled.div<ISHeaderWrapperProps>(
  ({ onlyText }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 442px;
    margin-top: 8px;
    text-align: center;

    ${onlyText ? media.xl : media.md} {
      margin-top: 16px;
    }

    ${media.xs} {
      width: 100%;
    }
  `
)

export const SHeaderText = styled(Paragraph)(
  ({ theme }) => css`
    color: ${theme.palette.textGray};
  `
)

export const SHeaderLink = styled(NavLink)(
  ({ theme }) => css`
    margin-left: 12px;
    font: ${theme.fontSystem.middle};

    ${media.xl} {
      font: ${theme.fontSystem.large};
    }
  `
)

export const SProfileChangeWrapper = styled.div(
  () => css`
    width: 496px;
    text-align: center;

    ${media.lessThan('xl')} {
      margin-bottom: 8px;
    }

    ${media.xs} {
      width: 100%;
    }
  `
)

export const SProfileChangeIcon = styled(CheckboxCircle)(
  ({ theme }) => css`
    width: 64px;
    height: 64px;
    fill: ${theme.palette.emerald};

    ${media.md} {
      width: 80px;
      height: 80px;
    }
  `
)
export const SProfileChangeTitle = styled(Title)(
  () => css`
    margin-top: 16px;

    ${media.md} {
      margin-top: 24px;
    }
  `
)

export const SProfileChangeSubtitle = styled(Paragraph)(
  ({ theme }) => css`
    margin-top: 16px;
    color: ${theme.palette.textGray};
  `
)

export const SProfileChangeDivider = styled.div(
  ({ theme }) => css`
    width: 100%;
    height: 1px;
    margin-top: 24px;
    background-color: ${theme.palette.dividerLight};

    ${media.xl} {
      margin-top: 32px;
    }
  `
)

export const SProfileChangeText = styled(Paragraph)(
  () => css`
    margin-top: 24px;

    ${media.xl} {
      margin-top: 32px;
    }
  `
)
