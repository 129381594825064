import { request } from './api'

const applyDelayedPd = async (token: string) => {
  await request({
    location: `core-personal:apply/delayed`,
    method: 'get',
    params: {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  })
}

export default applyDelayedPd
