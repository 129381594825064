const OAUTH_LIST_PLATFORM = ['sberId', 'sberBusinessId']
const OAUTH_LIST_COOP = ['sberBusinessId', 'sberId']

function getOauthList(): string[] {
  switch (process.env.REACT_APP_BUILD) {
    case 'coop':
      return OAUTH_LIST_COOP
    default:
      return OAUTH_LIST_PLATFORM
  }
}

export default getOauthList()
