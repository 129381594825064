import styled, { css } from 'styled-components'

export const SError = styled.span(
  ({ theme }) => css`
    display: block;
    margin-top: 10px;
    margin-bottom: -9px;
    padding: 0 16px;
    color: ${theme.palette.pink};
    font: ${theme.font.desktop.p3};
    text-align: left;
  `
)
