import { FC, useEffect, useState } from 'react'
import { Cookies } from 'react-cookie'
import { RouteComponentProps } from 'react-router-dom'
import get from 'lodash/get'

import { configs } from 'eco-api'

import { REDIRECT_URL } from 'configs/consts'
import { scopesConfig } from 'configs/scopes'

import SubmitButton from '../../../FormBuilder/components/SubmitButton'

import {
  SProviderAccess,
  SProviderIntro,
  SProviderScope,
  SProviderScopeField,
  SProviderScopeFieldIcon,
  SProviderScopeTitle,
  SProviderWrapper,
} from './styled.index'

const { axios, getApiUrl, AUTH_COOKIE_NAME } = configs

// Пример ссылки:
// https://core-router.dev.dasreda.ru/oauth20/authorize?response_type=code&client_id=_test_&redirect_uri=http:%2F%2Fdasreda.ru&scope=read

// TODO:
// 1) Как мне понять, что юзер уже однажды выдал разрешения на пользованием своим аккаунтом?
// 2) Необходима обработка ошибки беком в случае неуспешной авторизации

const cookies = new Cookies()

interface IDSProvider {
  location: {
    query: {
      client_id: string
      redirect_uri: string
      response_type: string
      scope: string
    }
  }
}

const DSProvider: FC<RouteComponentProps & IDSProvider> = ({ history, location }) => {
  const { push } = history
  const { pathname, search, query } = location
  const { client_id, redirect_uri, response_type, scope: queryScope } = query

  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<boolean>(false)
  const [scope, setScope] = useState<(keyof typeof scopesConfig)[]>([])

  useEffect(() => {
    const authorize = async () => {
      try {
        const result = await axios().request({
          url: getApiUrl('core-router/:oauth20/authorize'),
          method: 'get',
          withCredentials: true,
          params: {
            response_type,
            client_id,
            redirect_uri,
            scope: queryScope,
          },
        })

        const { authorized: isAuthorize, scope: scopeValues } = get(result, 'data') || {}

        if (isAuthorize) {
          setLoading(false)
          setScope(scopeValues)
        } else {
          localStorage.setItem(REDIRECT_URL, `${pathname}${search}&type=oAuth`)
          push(`/login${search}&type=oAuth`)
        }
      } catch (err) {
        setLoading(false)
        setError(true)
      }
    }

    authorize()
  }, [])

  if (loading) return <div>Загрузка...</div>
  if (error) return <div>Произошла ошибка</div>

  return (
    <SProviderWrapper>
      <div>
        <h2>Авторизация на &laquo;{client_id}&raquo;</h2>
        <SProviderIntro level={2}>
          Сайт{' '}
          <a href={redirect_uri} target="_blank" rel="noopener noreferrer">
            &quot;{client_id}&quot;
          </a>{' '}
          получит доступ к:
        </SProviderIntro>

        <form method="POST" action={getApiUrl('core-router/:oauth20/authorize')}>
          <input name="scope" defaultValue={scope.join(' ')} hidden />
          <input name="token" defaultValue={cookies.get(AUTH_COOKIE_NAME)} hidden />

          <SProviderAccess>
            {scope.map((scopeVal) => {
              // Скоупы пока не утверждены, поэтому подстраховываюсь пустым объектом
              const { fields, title } = scopesConfig[scopeVal] || {}

              return (
                <SProviderScope key={scopeVal}>
                  <SProviderScopeTitle>{title}</SProviderScopeTitle>

                  {fields.map(({ name, iconUrl }) => (
                    <SProviderScopeField key={`${scopeVal}-field-${name}`}>
                      <SProviderScopeFieldIcon iconUrl={iconUrl} />
                      <div>{name}</div>
                    </SProviderScopeField>
                  ))}
                </SProviderScope>
              )
            })}
          </SProviderAccess>

          <SubmitButton type="submit" color="textGray">
            Авторизовать
          </SubmitButton>
          <a href={redirect_uri}>Отменить</a>
        </form>
      </div>

      <div>
        <span>
          После авторизации вы будете перенаправлены на{' '}
          <a href={redirect_uri} target="_blank" rel="noopener noreferrer">
            {redirect_uri}
          </a>
        </span>
      </div>
    </SProviderWrapper>
  )
}

export default DSProvider
