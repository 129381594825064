import { media, Paragraph, Title } from '@dasreda/components'
import styled, { css } from 'styled-components'

import SubmitButton from '../FormBuilder/components/SubmitButton'

export const SThankYouWrapper = styled.div(
  () => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 85px 24px;
    text-align: center;

    ${media.md} {
      padding: 285px 24px;
    }

    ${media.lg} {
      width: 877px;
      padding: 155px 0;
    }

    ${media.xl} {
      padding: 255px 0;
    }

    ${media.xxl} {
      padding: 280px 0;
    }
  `
)

export const SThankYouTitle = styled(Title)(
  ({ theme }) => css`
    color: ${theme.palette.emerald};
  `
)

export const SThankYouSubtitle = styled(Paragraph)(
  ({ theme }) => css`
    margin-top: 8px;
    margin-bottom: 20px;
    color: ${theme.palette.textGray};
  `
)

export const SThankYouDescription = styled(Paragraph)(
  () => css`
    max-width: 591px;
    margin-top: 24px;
  `
)

export const SButton = styled(SubmitButton)(
  ({ theme, disabled }) => css`
    width: unset;
    min-width: 350px;
    color: ${disabled && theme.palette.emerald};
  `
)
