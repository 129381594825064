import { AxiosError } from 'axios'
import qs from 'qs'
import { TResUser } from 'types/user'

import DEFAULT_AGREEMENT_TYPES from 'configs/agreementTypes'

import { Footer, Header } from 'components'
import { SUBSCRIPTION_DISPATCH } from 'configs/agreements'
import CHANNEL_IDS from 'configs/channels'
import { dummyUser } from 'configs/consts'
import { DATALAYER_CUSTOM_EVENT, DATALAYER_EVENT_REGISTRATION } from 'configs/dataLayer'
import { emailFieldProps, subscriptionAgree } from 'configs/defaultFieldsProps'
import { TFormConfig, TFormConfigFn } from 'containers/FormBuilder'
import { TField, TValues } from 'containers/FormBuilder/components/Field'
import { request } from 'utils/api'
import { setAuthData } from 'utils/auth'
import getFormFieldsByType from 'utils/getFormFieldsByType'
import { sendNewUserToGTR } from 'utils/getResponse'
import { redirect, redirectPreRenderHook } from 'utils/redirect'
import { commonRegistrationActions } from 'utils/registration'
import { dataLayerHandler } from 'utils/sendDataLayer'
import { email as emailValidator, required } from 'utils/validators'

const channelId = CHANNEL_IDS.EML

const config = {
  subscription: {
    title: 'Войдите или зарегистрируйтесь для продолжения покупки',
    initialValues: { [SUBSCRIPTION_DISPATCH]: true },
    extraFields: [
      {
        ...subscriptionAgree,
        validators: [required],
      },
    ],
  },
  oAuth: {
    withLogo: false,
  },
}

type TPartialFormConfig = Partial<Pick<TFormConfig, 'title' | 'withLogo'>>
interface IReturnConfig extends TPartialFormConfig {
  initialValues?: TValues
  extraFields?: TField[]
}

const emailRegistration: TFormConfigFn<{ type: string }> = ({
  location: {
    query: { type = '' },
  },
}) => {
  const {
    title = 'Зарегистрироваться',
    withLogo = true,
    initialValues: extraInitialValues = [],
    extraFields = [],
  } = getFormFieldsByType<IReturnConfig>(config)(type)

  return {
    title,
    submitButtonText: 'Зарегистрироваться',
    header: <Header type="registration" />,
    footer: <Footer type="dsAgree" />,
    initialValues: { ...extraInitialValues },
    withLogo,
    fields: [
      {
        ...emailFieldProps,
        validators: [required, emailValidator],
        fieldSuffix: 'regByEmail',
      },
      ...extraFields,
    ],
    successMessage: 'На указанный вами e-mail отправлено письмо с паролем для входа',
    agreementTypesToLoad: DEFAULT_AGREEMENT_TYPES,
    defaultTruthForAgreementTypes: DEFAULT_AGREEMENT_TYPES,
    postRender: redirectPreRenderHook,
    onRequest: (values) => {
      const reqParams = {
        location: `core-router/v2:register/${channelId}`,
        params: {
          data: {
            ...dummyUser,
            ...values,
            channelId,
          },
        },
      }

      return request(reqParams)
    },
    postHook: async (error, response, { history: { push }, location: { query } }, values) => {
      const { username: email } = values
      const nextParams: { [key: string]: string } = query

      if (response) {
        const nextAuthData = response as TResUser
        const { data } = response as TResUser

        nextAuthData.data = {
          ...data,
          email,
        }

        dataLayerHandler({
          event: DATALAYER_CUSTOM_EVENT,
          eventCategory: DATALAYER_EVENT_REGISTRATION,
          eventAction: channelId,
          userId: data?.id,
        })

        setAuthData(nextAuthData)
        commonRegistrationActions()

        const { publicName, agreements } = nextAuthData.data
        await sendNewUserToGTR({ email, publicName, agreements, channelId }, { isFastRegChannel: true })

        nextParams.email = email

        redirect({ push, url: `/profile-change?${qs.stringify(nextParams)}` })
      }
      if (error) {
        delete nextParams.action

        if ((error as AxiosError).response?.status === 409) {
          switch (nextParams.type) {
            case 'subscription':
            case 'newsletter':
              setTimeout(() => push(`/login?${qs.stringify(nextParams)}`, 2500))
              break
            default:
              console.error(error)
          }
        }
      }
    },
  }
}

export default emailRegistration
