import AgreementsMSP from 'containers/AgreementsMSP'
import AgreementsRBO from 'containers/AgreementsRBO'
import EmailConfirmation from 'containers/EmailConfirmation'
import SbbolProvider from 'containers/OAuth/containers/Consumer/Sbbol'
import SberProvider from 'containers/OAuth/containers/Consumer/SberId'
import DSProvider from 'containers/OAuth/containers/Provider'
import SbbolRedirect from 'containers/SbbolRedirect'
import SbolRedirect from 'containers/SbolRedirect'
import ThankYou from 'containers/ThankYou'

export default [
  {
    path: '/email-confirmation/:id/:uin',
    component: EmailConfirmation,
    exact: true,
  },
  {
    path: '/sbol-result',
    component: SberProvider,
    exact: true,
  },
  {
    path: '/sbbol-dcb',
    component: SbbolRedirect,
    exact: true,
  },
  {
    path: '/sbol-dcb',
    component: SbolRedirect,
    exact: true,
  },
  {
    path: '/sbbol-result',
    component: SbbolProvider,
    exact: true,
  },
  {
    path: '/authorize',
    component: DSProvider,
    exact: true,
  },
  {
    path: '/agreements-rbo',
    component: AgreementsRBO,
    exact: true,
  },
  {
    path: '/registration-secondary/msp-agreements',
    component: AgreementsMSP,
    exact: true,
  },
  {
    path: '/confirmation',
    component: ThankYou,
    exact: true,
  },
]
