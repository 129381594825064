import { useState } from 'react'
import getApolloClient from 'gql/helpers/getApolloClient'
import { loader } from 'graphql.macro'
import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'

import DEFAULT_AGREEMENT_TYPES from 'configs/agreementTypes'

import { DUMMY_AGREEMENT } from 'configs/agreements'
import CHANNEL_IDS from 'configs/channels'
import { dummyUser } from 'configs/consts'
import { TFormConfig } from 'containers/FormBuilder'
import SubmitButton from 'containers/FormBuilder/components/SubmitButton'
import { SCheckboxInput } from 'containers/FormBuilder/styled.index'
import { transformAgreements } from 'utils/agreements'
import { request, setAuthData } from 'utils/auth'
import { sendNewUserToGTR } from 'utils/getResponse'
import { commonRegistrationActions } from 'utils/registration'
import {
  antiFraudPhone,
  email as emailValidator,
  inn as innValidator,
  phone as phoneValidator,
  required,
} from 'utils/validators'

import useSendSmsToTemplateSlug from 'hooks/useSendSmsToTemplateSlug'

import {
  emailFieldProps,
  innFieldProps,
  middleNameFieldProps,
  nameFieldProps,
  phoneFieldProps,
  surnameFieldProps,
} from '../../defaultFieldsProps'

const userEnrollMutation = loader('src/gql/mutations/userEnroll.gql')

const SMS_TEMPLATE_SLUG = 'startuj-uverenno-iyun'
const LMS_GROUP_CODE = '3rTI5pcs9gOUDftXNM4BB'
const channelId = CHANNEL_IDS.LMS

const SubmitBlock: TFormConfig['submitBlock'] = ({
  hasValidationErrors,
  agreementDocuments,
  form,
  setError,
  setSucceeded,
}) => {
  const [loading, setLoading] = useState(false)
  const { values: formValues } = form.getState()

  let nextData = cloneDeep(formValues)
  DEFAULT_AGREEMENT_TYPES.forEach((agreementType) => {
    nextData[agreementType] = true
  })
  nextData = transformAgreements(nextData, agreementDocuments)

  const buttonDisabled = hasValidationErrors

  const sendSmsToTemplateSlug = useSendSmsToTemplateSlug(SMS_TEMPLATE_SLUG)

  const userEnroll = async (email: string, program: string) => {
    const apolloClient = await getApolloClient

    return apolloClient.mutate({
      mutation: userEnrollMutation,
      variables: {
        params: {
          email,
          program,
        },
      },
      fetchPolicy: 'network-only',
    })
  }

  const submitHandler = async () => {
    const { values } = form.getState()
    const { username: email, phone, firstName, middleName, lastName, inn } = values

    setError(null)
    setSucceeded(null)
    setLoading(true)

    try {
      const config = {
        location: `core-router/v2:register/${channelId}`,
        params: {
          data: {
            ...dummyUser,
            email,
            lastName,
            firstName,
            middleName,
            phone,
            inn,
            ...nextData,
            isFirstLogin: true,
            channelId,
          },
        },
      }

      const { data } = await request(config)

      if (data) {
        setAuthData({ data })
        sendSmsToTemplateSlug(`+${phone}`)
        commonRegistrationActions()

        const { publicName, agreements } = data

        await sendNewUserToGTR(
          { email, firstName, lastName, publicName, agreements, channelId },
          {
            isFastRegChannel: true,
            campaignId: 'LS',
            customOptions: {
              phone_1: phone,
            },
          }
        )

        userEnroll(email, LMS_GROUP_CODE)
      }
      form.restart()
      setSucceeded(true)
    } catch (e) {
      const errorCode = get(e, 'response.data.errors[0].code')
      if (errorCode === 'EXECUTION_ERROR') {
        sendSmsToTemplateSlug(`+${phone}`)
        commonRegistrationActions()

        await sendNewUserToGTR(
          { email, firstName, lastName, channelId },
          {
            isFastRegChannel: true,
            campaignId: 'LS',
            customOptions: {
              phone_1: phone,
            },
          }
        )

        userEnroll(email, LMS_GROUP_CODE)

        form.restart()
        setSucceeded(true)
      } else {
        setError(get(e, 'response.data.errors[0]._error', 'Произошла не известная ошибка, попробуйте еще раз'))
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <SubmitButton type="button" onClick={submitHandler} color="textGray" disabled={buttonDisabled || loading}>
      {loading ? 'Выполняется запрос' : 'Зарегистрироваться'}
    </SubmitButton>
  )
}

const mspRegistrationBotJune: TFormConfig = {
  title: 'Регистрация на июнь',
  formWidth: 520,
  submitButtonText: 'Зарегистрироваться',
  initialValues: {},
  fields: [
    {
      ...surnameFieldProps,
      validators: [required, ...(surnameFieldProps.validators as [])],
    },
    {
      ...nameFieldProps,
      validators: [required, ...(nameFieldProps.validators as [])],
    },
    {
      ...middleNameFieldProps,
      validators: [required, ...(middleNameFieldProps.validators as [])],
    },
    {
      ...emailFieldProps,
      validators: [required, emailValidator],
    },
    {
      ...innFieldProps,
      validators: [required, innValidator],
    },
    {
      ...phoneFieldProps,
      validators: [required, phoneValidator, antiFraudPhone],
    },
    {
      name: DUMMY_AGREEMENT,
      component: SCheckboxInput,
      label: (
        <span>
          Я подтверждаю, что вышеприведённые сведения заполнены со слов представителя субъекта малого среднего
          предпринимательства, и он уведомлен о передаче его{' '}
          <a target="_blank" rel="noopener noreferrer" href="https://cdn.dasreda.ru/fonts-static/soglasie-pd.pdf">
            персональных данных
          </a>{' '}
          в адрес АО «Деловая среда»
        </span>
      ),
      qa: 'dispatchMspBizAgree_checkbox',
      validators: [required],
    },
  ],
  successMessage: 'Сообщение отправлено!',
  agreementTypesToLoad: DEFAULT_AGREEMENT_TYPES,
  submitBlock: SubmitBlock,
  onRequest: () => Promise.resolve(true),
}

export default mspRegistrationBotJune
