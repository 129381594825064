import { TRequest } from 'types/api'

import { Footer, Header } from 'components'
import { TFormConfig } from 'containers/FormBuilder'
import { request } from 'utils/api'
import { redirect } from 'utils/redirect'
import { transformError } from 'utils/transformError'
import { phone as phoneValidator, required } from 'utils/validators'

import { phoneFieldProps } from '../../defaultFieldsProps'

const passwordResetRequest: TFormConfig = {
  title: 'Забыли пароль?',
  header: <Header type="password-reset-phone" />,
  submitButtonText: 'Восстановить пароль',
  fields: [
    {
      ...phoneFieldProps,
      qa: 'password_request_field',
      validators: [required, phoneValidator],
      name: 'phone',
    },
  ],
  successMessage: 'Инструкции для смены пароля отправлены вам на телефон',
  onRequest: (values) => {
    const config: TRequest = {
      location: 'core-router/v2:user/restore/phone',
      method: 'post',
      params: {
        data: values,
      },
    }

    return request(config)
  },
  postHook: async (_error, _response, { history: { push } }, { phone }) => {
    if (_error) {
      return { ...transformError(_error) }
    }

    return redirect({ push, url: `/password-change-phone?phone=${phone}` })
  },
  footer: <Footer type="resetPasswordPhone" align="center" />,
}

export default passwordResetRequest
