import { FC, memo, useMemo } from 'react'

import { IOAuthProviders, TGetBtns, TGetBtnsReturn } from './types'

import { configs } from 'eco-api'

import oauthList from 'configs/oauthList'

import useQuery from 'hooks/useQuery'

import { btnsConfig } from './config'
import { SSeparator, SSocialButtons, SWrapper } from './styled.index'

const { resolveEnvironment } = configs

const getBtns: TGetBtns = (list, type) => {
  return list.reduce((acc, provider) => {
    const btnsByType = btnsConfig[type]
    const btn = btnsByType[provider as keyof typeof btnsByType]

    if (btn) acc.push([provider, btn])
    return acc
  }, [] as TGetBtnsReturn)
}

// TODO: реализовать все заложенные дизайном варианты (2 квартал 2021, задачи пока нет)
// ['vk', 'facebook', 'instagram']

const OAuthProviders: FC<IOAuthProviders> = ({
  list = oauthList,
  hasSeparator = true,
  separatorPosition = 'top',
  profile = 'DASREDA',
  withoutMargin = false,
}) => {
  const socialProviders = useMemo(() => getBtns(list, 'social'), [list])
  const otherProviders = useMemo(() => getBtns(list, 'other'), [list])
  const env = resolveEnvironment()
  const query = useQuery()

  return (
    <SWrapper withoutMargin={withoutMargin}>
      {hasSeparator && separatorPosition === 'top' && (
        <SSeparator>
          <span>или</span>
        </SSeparator>
      )}
      {otherProviders.map((config) => {
        const [provider, Button] = config

        if (env === 'prod' && provider === 'sberBusinessId' && !query.get('testSbbol')) {
          return null
        }

        return <Button key={provider} profile={profile} />
      })}
      {socialProviders.length ? <SSocialButtons>{socialProviders.map(([, Button]) => Button)}</SSocialButtons> : null}
      {hasSeparator && separatorPosition === 'bottom' && (
        <SSeparator>
          <span>или</span>
        </SSeparator>
      )}
    </SWrapper>
  )
}

export default memo(OAuthProviders)
