import { NavLink } from 'react-router-dom'
import { media } from '@dasreda/components'
import { Eye, EyeBlock } from '@dasreda/icons'
import styled, { css } from 'styled-components'

export const SFieldSuffixLink = styled(NavLink)(
  ({ theme }) => css`
    padding-bottom: 2px;
    font: ${theme.fontSystem.small};

    ${media.lessThan('xl')} {
      padding-right: 8px;
    }

    ${media.xl} {
      font: ${theme.fontSystem.middle};
    }
  `
)

export const SEyeIcon = styled(Eye)(
  ({ theme }) => css`
    display: block;
    width: 24px;
    height: 24px;
    fill: ${theme.palette.textGray};

    ${media.lessThan('xl')} {
      margin-right: 8px;
    }
  `
)

export const SEyeBlockIcon = styled(EyeBlock)(
  ({ theme }) => css`
    display: block;
    width: 24px;
    height: 24px;
    fill: ${theme.palette.textGray};

    ${media.lessThan('xl')} {
      margin-right: 8px;
    }
  `
)

export const SFieldSuffixWithState = styled.div``
