import { TResUser } from 'types/user'

import DEFAULT_AGREEMENT_TYPES from 'configs/agreementTypes'

import { TERMS_OF_USE_DS_DISPATCH } from 'configs/agreements'
import CHANNEL_IDS from 'configs/channels'
import { DATALAYER_CUSTOM_EVENT, DATALAYER_EVENT_REGISTRATION } from 'configs/dataLayer'
import { TFormConfig } from 'containers/FormBuilder'
import { SCheckboxInput } from 'containers/FormBuilder/styled.index'
import { request } from 'utils/api'
import { setAuthData } from 'utils/auth'
import { sendNewUserToGTR } from 'utils/getResponse'
import { redirect } from 'utils/redirect'
import { commonRegistrationActions } from 'utils/registration'
import { dataLayerHandler } from 'utils/sendDataLayer'
import { email as emailValidator, phone, required } from 'utils/validators'

import {
  emailFieldProps,
  middleNameFieldProps,
  nameFieldProps,
  phoneFieldProps,
  surnameFieldProps,
} from '../../defaultFieldsProps'

const channelId = CHANNEL_IDS.MFC

const AGREEMENT_TYPES: [...typeof DEFAULT_AGREEMENT_TYPES, typeof TERMS_OF_USE_DS_DISPATCH] = [
  ...DEFAULT_AGREEMENT_TYPES,
  TERMS_OF_USE_DS_DISPATCH,
]

const defaultRegistration: TFormConfig = {
  title: 'Регистрация',
  submitButtonText: 'Зарегистрироваться',
  initialValues: {},
  fields: [
    {
      ...nameFieldProps,
      validators: [required],
    },
    {
      ...middleNameFieldProps,
      validators: [required],
    },
    {
      ...surnameFieldProps,
      validators: [required],
    },
    {
      ...emailFieldProps,
      validators: [required, emailValidator],
    },
    {
      ...phoneFieldProps,
      validators: [required, phone],
    },
    {
      name: TERMS_OF_USE_DS_DISPATCH,
      component: SCheckboxInput,
      label: (
        <span>
          Я принимаю условия{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://cdn.dasreda.ru/fonts-static/polzovatelskoe_soglashenie.pdf"
          >
            Пользовательского соглашения
          </a>{' '}
          и даю своё{' '}
          <a target="_blank" rel="noopener noreferrer" href="https://cdn.dasreda.ru/fonts-static/soglasie-pd.pdf">
            согласие
          </a>{' '}
          АО «Деловая среда» на обработку моих персональных данных, в соответствии с Федеральным законом от 27.07.2006
          года №152-ФЗ «О персональных данных», на условиях и для целей, определенных{' '}
          <a target="_blank" rel="noopener noreferrer" href="https://cdn.dasreda.ru/fonts-static/company-policy.pdf">
            Политикой конфиденциальности
          </a>
        </span>
      ),
      qa: 'dispatchDsAgree_checkbox',
      validators: [required],
    },
  ],
  successMessage: 'На указанный вами телефон отправлено СМС с паролем для входа',
  agreementTypesToLoad: AGREEMENT_TYPES,
  defaultTruthForAgreementTypes: DEFAULT_AGREEMENT_TYPES,
  onRequest: (values) => {
    const config = {
      location: `core-router/v2:register/${channelId}`,
      params: {
        data: {
          ...values,
          channelId,
        },
      },
    }

    return request(config)
  },
  postHook: async (_, response, { history: { push } }, values) => {
    const { username: email, firstName, lastName } = values

    if (response) {
      setAuthData(response as TResUser)
      commonRegistrationActions()
      const { data } = response as TResUser

      const { publicName, agreements } = data
      await sendNewUserToGTR(
        { email, firstName, lastName, publicName, agreements, channelId },
        { isFastRegChannel: true }
      )

      dataLayerHandler({
        event: DATALAYER_CUSTOM_EVENT,
        eventCategory: DATALAYER_EVENT_REGISTRATION,
        eventAction: channelId,
        userId: data?.id,
      })

      // не записываю сразу в GTR, т.к. следующим шагом заставляем авторизовываться
      // бизнес не считает этот канал быстрым, но по факту он быстрый
      redirect({ push, url: `/login?username=${email}` })
    }
  },
}

export default defaultRegistration
