import { useState } from 'react'
import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import RandExp from 'randexp'

import DEFAULT_AGREEMENT_TYPES from 'configs/agreementTypes'

import { DUMMY_AGREEMENT } from 'configs/agreements'
import CHANNEL_IDS from 'configs/channels'
import { DATALAYER_CUSTOM_EVENT, DATALAYER_EVENT_REGISTRATION } from 'configs/dataLayer'
import { TFormConfig } from 'containers/FormBuilder'
import SubmitButton from 'containers/FormBuilder/components/SubmitButton'
import { SCheckboxInput } from 'containers/FormBuilder/styled.index'
import { transformAgreements } from 'utils/agreements'
import { request, setAuthData } from 'utils/auth'
import { checkIsInnExist } from 'utils/checkInnExists'
import { checkIsPhoneExist } from 'utils/checkPhoneExists'
import { sendNewUserToGTR } from 'utils/getResponse'
import { commonRegistrationActions } from 'utils/registration'
import { dataLayerHandler } from 'utils/sendDataLayer'
import {
  antiFraudPhone,
  email as emailValidator,
  inn as innValidator,
  phone as phoneValidator,
  required,
} from 'utils/validators'

import {
  emailFieldProps,
  innFieldProps,
  middleNameFieldProps,
  nameFieldProps,
  phoneFieldProps,
  surnameFieldProps,
} from '../../defaultFieldsProps'

const channelId = CHANNEL_IDS.MSP

const generateSbrPassword = () =>
  // пароль от 8 до 12 символов с наличием цмфр и латиницы [A-z]
  new RandExp(/(([a-z]{3,5}[\d])|([\d]{3,5}[a-z])){2}/i).gen()

const SubmitBlock: TFormConfig['submitBlock'] = ({
  hasValidationErrors,
  agreementDocuments,
  form,
  setError,
  setSucceeded,
}) => {
  const [loading, setLoading] = useState(false)
  const { values: formValues } = form.getState()

  let nextData = cloneDeep(formValues)
  DEFAULT_AGREEMENT_TYPES.forEach((agreementType) => {
    nextData[agreementType] = true
  })
  nextData = transformAgreements(nextData, agreementDocuments)

  const buttonDisabled = hasValidationErrors

  const submitHandler = async () => {
    const { values } = form.getState()
    const { username: email, phone, firstName, middleName, lastName, inn } = values

    setError(null)
    setSucceeded(null)
    setLoading(true)

    try {
      const resultCheckPhonePromise = checkIsPhoneExist(phone)
      const resultCheckInnPromise = checkIsInnExist(inn)

      const resultCheckPhone = await resultCheckPhonePromise

      if (resultCheckPhone.isPhoneExist) {
        setError('Такой номер телефона уже зарегистрирован в системе')

        return
      }

      if (resultCheckPhone.err) {
        setError('Произошла неизвестная ошибка, попробуйте еще раз')

        return
      }

      const resultCheckInn = await resultCheckInnPromise

      if (resultCheckInn.isInnExist) {
        setError('Данный ИНН уже зарегистрирован в системе')

        return
      }

      if (resultCheckInn.err) {
        setError('Произошла неизвестная ошибка, попробуйте еще раз')

        return
      }
      const sbrPassword = generateSbrPassword()
      values.password = sbrPassword

      const config = {
        location: `core-router/v2:register/${channelId}`,
        params: {
          data: {
            email,
            password: sbrPassword,
            lastName,
            firstName,
            middleName,
            phone,
            inn,
            ...nextData,
            isFirstLogin: true,
          },
        },
      }

      const { data } = await request(config)

      if (data) {
        setAuthData({ data })
        commonRegistrationActions()

        dataLayerHandler({
          event: DATALAYER_CUSTOM_EVENT,
          eventCategory: DATALAYER_EVENT_REGISTRATION,
          eventAction: channelId,
          userId: data?.id,
        })

        const { publicName, agreements } = data

        await sendNewUserToGTR(
          { email, firstName, lastName, publicName, agreements, channelId },
          { isFastRegChannel: true }
        )
      }
      form.restart()
      setSucceeded(true)
    } catch (e) {
      setError(get(e, 'response.data.errors[0]._error', 'Произошла не известная ошибка, попробуйте еще раз'))
    } finally {
      setLoading(false)
    }
  }

  return (
    <SubmitButton type="button" onClick={submitHandler} color="textGray" disabled={buttonDisabled || loading}>
      {loading ? 'Выполняется запрос' : 'Зарегистрироваться'}
    </SubmitButton>
  )
}

const mspRegistrationClone: TFormConfig = {
  title: 'Регистрация',
  submitButtonText: 'Зарегистрироваться',
  initialValues: {},
  fields: [
    {
      ...surnameFieldProps,
      validators: [required, ...(surnameFieldProps.validators as [])],
    },
    {
      ...nameFieldProps,
      validators: [required, ...(nameFieldProps.validators as [])],
    },
    {
      ...middleNameFieldProps,
      validators: [required, ...(middleNameFieldProps.validators as [])],
    },
    {
      ...emailFieldProps,
      validators: [required, emailValidator],
    },
    {
      ...innFieldProps,
      validators: [required, innValidator],
    },
    {
      ...phoneFieldProps,
      validators: [required, phoneValidator, antiFraudPhone],
    },
    {
      name: DUMMY_AGREEMENT,
      component: SCheckboxInput,
      label: (
        <span>
          Я подтверждаю, что вышеприведённые сведения заполнены со слов представителя субъекта малого среднего
          предпринимательства, и он уведомлен о передаче его{' '}
          <a target="_blank" rel="noopener noreferrer" href="https://cdn.dasreda.ru/fonts-static/soglasie-pd.pdf">
            персональных данных
          </a>{' '}
          в адрес АО «Деловая среда»
        </span>
      ),
      qa: 'dispatchMspBizAgree_checkbox',
      validators: [required],
    },
  ],
  successMessage: 'Сообщение отправлено!',
  agreementTypesToLoad: DEFAULT_AGREEMENT_TYPES,
  submitBlock: SubmitBlock,
  onRequest: () => Promise.resolve(true),
}

export default mspRegistrationClone
