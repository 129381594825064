import { TResUser } from 'types/user'

import { IFormConfigFnPropsWithClient } from './types'
import DEFAULT_AGREEMENT_TYPES from 'configs/agreementTypes'

import { Footer, Header } from 'components'
import { SENDINGS_AGREEMENT } from 'configs/agreements'
import CHANNEL_IDS from 'configs/channels'
import { dummyUser } from 'configs/consts'
import { DATALAYER_CUSTOM_EVENT, DATALAYER_EVENT_REGISTRATION } from 'configs/dataLayer'
import { emailFieldProps, nameFieldProps, sendingsAgreement } from 'configs/defaultFieldsProps'
import { TFormConfig, TFormConfigFn } from 'containers/FormBuilder'
import { TValues } from 'containers/FormBuilder/components/Field'
import OAuthBtns from 'containers/OAuth/containers/Buttons'
import { request } from 'utils/api'
import { setAuthData } from 'utils/auth'
import getFormFieldsByType from 'utils/getFormFieldsByType'
import { redirect, redirectPreRenderHook } from 'utils/redirect'
import { commonRegistrationActions } from 'utils/registration'
import { dataLayerHandler } from 'utils/sendDataLayer'
import { email as emailValidator, required } from 'utils/validators'

const channelId = CHANNEL_IDS.HSF

const config = {
  course_access: {
    title: 'Уроки курса доступны только зарегистрированным пользователям',
  },
}

type TPartialFormConfig = Partial<Pick<TFormConfig, 'title' | 'header' | 'withLogo'>>
interface IReturnConfig extends TPartialFormConfig {
  initialValues?: TValues
}

const defaultFastRegistration: TFormConfigFn<IFormConfigFnPropsWithClient> = ({
  location: {
    query: { client_id, type, system, redirect: redirectURl },
  },
}) => {
  const defaultTitle = client_id
    ? `Войдите или зарегистрируйтесь для авторизации на &quot;${client_id}&quot;`
    : 'Регистрация'

  const { title = defaultTitle, initialValues: extraInitialValues } = getFormFieldsByType<IReturnConfig>(config)(type)

  const isLMS = system?.toUpperCase() === 'LMS'

  return {
    title,
    submitButtonText: 'Зарегистрироваться',
    header: <Header type="registration" />,
    footer: isLMS ? undefined : (
      <>
        <OAuthBtns profile="DASREDA" />
        <Footer type="dsAgree" />
      </>
    ),
    initialValues: {
      ...extraInitialValues,
      [SENDINGS_AGREEMENT]: true,
    },
    verticalAlign: 'flex-start',
    fields: [
      {
        ...nameFieldProps,
        validators: [required, ...(nameFieldProps.validators as [])],
      },
      {
        ...emailFieldProps,
        validators: [required, emailValidator],
      },
      {
        ...sendingsAgreement,
      },
    ],
    successMessage:
      'На указанный вами e-mail отправлено письмо со ссылкой для подтверждения регистрации и паролем для входа',
    postRender: redirectPreRenderHook,
    agreementTypesToLoad: DEFAULT_AGREEMENT_TYPES,
    defaultTruthForAgreementTypes: DEFAULT_AGREEMENT_TYPES,
    onRequest: (values) => {
      const reqParams = {
        location: `core-router/v2:register/${channelId}`,
        params: {
          withCredentials: true,
          data: {
            ...dummyUser,
            ...values,
            isFirstLogin: true,
            channelId,
            target: redirectURl,
          },
        },
      }

      return request(reqParams)
    },
    postHook: async (_, response, { history: { push } }, { username }) => {
      if (response) {
        setAuthData(response as TResUser)
        commonRegistrationActions()

        const { data } = response as TResUser

        dataLayerHandler({
          event: DATALAYER_CUSTOM_EVENT,
          eventCategory: DATALAYER_EVENT_REGISTRATION,
          eventAction: channelId,
          userId: data?.id,
        })
        redirect({ push, url: '/confirmation' }, { userEmail: username })
      }
    },
  }
}

export default defaultFastRegistration
