import { FC } from 'react'
import { useGrid } from '@dasreda/components'

import { configs } from 'eco-api'

import { SDemoAccessButton } from './styled.index'

const { resolvePortalUrl } = configs

const DemoAccess: FC = () => {
  const media = useGrid()
  return (
    <SDemoAccessButton
      to={`${resolvePortalUrl()}demo/registrateExistedCoop`}
      type="button"
      color="textGraphite"
      size={media.xlOrMore ? 'large' : 'medium'}
    >
      Демо-доступ
    </SDemoAccessButton>
  )
}

export default DemoAccess
