import { media, Paragraph, Title } from '@dasreda/components'
import styled, { css } from 'styled-components'

export const SFirstEnterWrapper = styled.div(
  () => css`
    width: 496px;
    text-align: center;

    ${media.lessThan('xl')} {
      margin-bottom: 8px;
    }

    ${media.xs} {
      width: 100%;
    }
  `
)

export const SFirstEnterTitle = styled(Title)(
  () => css`
    margin-top: 16px;

    ${media.md} {
      margin-top: 24px;
    }
  `
)

export const SFirstEnterDivider = styled(Title)(
  ({ theme }) => css`
    width: 100%;
    height: 1px;
    margin-top: 24px;
    background-color: ${theme.palette.border};

    ${media.xl} {
      margin-top: 32px;
    }
  `
)

export const SFirstEnterDescription = styled(Paragraph)(
  () => css`
    margin-top: 24px;

    ${media.xl} {
      margin-top: 32px;
    }
  `
)

export const SFirstEnterFormType = styled(Paragraph)(
  ({ theme }) => css`
    margin-top: 16px;
    color: ${theme.palette.textGray};
  `
)

export const SFirstEnterFormTypeText = styled.span(
  ({ theme }) => css`
    color: ${theme.palette.textBlack};
  `
)

export const SFirstEnterImage = styled.img(
  () => css`
    display: block;
    width: 64px;
    height: 64px;
    margin-right: auto;
    margin-left: auto;

    ${media.md} {
      width: 80px;
      height: 80px;
    }
  `
)
