import { ApolloClient } from '@apollo/client'
import { InMemoryCache } from '@apollo/client/cache'
import { BatchHttpLink } from '@apollo/client/link/batch-http'
import { setContext } from '@apollo/client/link/context'
import { apolloGetIntrospection, getApolloURI, getDefaultOptionsApollo, preparePossibleTypes } from '@dasreda/helpers'

import { configs, helpers } from 'eco-api'

const { resolveEnvironment } = configs
const { getHeaders } = helpers

const { REACT_APP_GQL_URL, REACT_APP_DOMAIN, REACT_APP_INTROSPECTION_TOKEN } = process.env

const getApolloClient = async () => {
  let possibleTypes = null
  const env = resolveEnvironment()
  const apolloUri = getApolloURI({ env, domain: REACT_APP_DOMAIN, gqlUrl: REACT_APP_GQL_URL })
  console.log('getApolloClient', REACT_APP_GQL_URL, REACT_APP_DOMAIN, env)

  if ((env?.includes('uat') || env === 'prod') && window.location.hostname !== 'localhost') {
    try {
      // eslint-disable-next-line import/no-unresolved, global-require
      possibleTypes = require('../__generated__/possibleTypes.json')
    } catch (e) {
      console.info('Error only for non production environment')
    }
  } else {
    const schema = await apolloGetIntrospection(apolloUri, REACT_APP_INTROSPECTION_TOKEN)

    possibleTypes = preparePossibleTypes(schema)
  }

  const httpLink = new BatchHttpLink({
    uri: apolloUri,
  })

  const authLink = setContext((_, { headers }) => {
    const headersList = getHeaders()

    return {
      headers: {
        ...headers,
        ...headersList,
        Source: 'apollo',
      },
    }
  })

  const cacheSettings = new InMemoryCache({
    possibleTypes,
  })

  const defaultOptions = getDefaultOptionsApollo()

  return new ApolloClient({
    link: authLink.concat(httpLink),
    cache: cacheSettings,
    defaultOptions,
  })
}

export default getApolloClient()
