import domclickRegistration from './domclickRegistration/domclickRegistration'
import kmSubscriptionExistingUser from './kmSubscription/kmSubscriptionExistingUser'
import kmSubscriptionNewUser from './kmSubscription/kmSubscriptionNewUser'
import kmSubscriptionRegistration from './kmSubscription/kmSubscriptionRegistration'
import agreementsUpdate from './agreementsUpdate'
import bizAdaptation from './bizAdaptation'
import defaultFastRegistration from './defaultFastRegistration'
import defaultLogin from './defaultLogin'
import defaultRegistration from './defaultRegistration'
import emailRegistration from './emailRegistration'
import loginByPhone from './loginByPhone'
import loginFrame from './loginFrame'
import mfcRegistration from './mfcRegistration'
import mspRegistration from './mspRegistration'
import mspRegistrationBot from './mspRegistrationBot'
import mspRegistrationBotAugust from './mspRegistrationBotAugust'
import mspRegistrationBotJuly from './mspRegistrationBotJuly'
import mspRegistrationBotJune from './mspRegistrationBotJune'
import mspRegistrationBotMay from './mspRegistrationBotMay'
import mspRegistrationBotSeptember from './mspRegistrationBotSeptember'
import mspRegistrationClone from './mspRegistrationClone'
import passwordChange from './passwordChange'
import passwordChangePhone from './passwordChangePhone'
import passwordResetEmail from './passwordResetEmail'
import passwordResetPhone from './passwordResetPhone'
import phoneRegistration from './phoneRegistration'
import profileChange from './profileChange'
import sbbolPremiumKm from './sbbolPremiumKm'

export default {
  defaultLogin,
  loginFrame,
  loginByPhone,
  defaultFastRegistration,
  defaultRegistration,
  domclickRegistration,
  emailRegistration,
  phoneRegistration,
  mspRegistration,
  mspRegistrationClone,
  mspRegistrationBot,
  mspRegistrationBotMay,
  mspRegistrationBotJune,
  mspRegistrationBotJuly,
  mspRegistrationBotAugust,
  mspRegistrationBotSeptember,
  kmSubscriptionRegistration,
  kmSubscriptionNewUser,
  kmSubscriptionExistingUser,
  mfcRegistration,
  passwordResetEmail,
  passwordResetPhone,
  passwordChange,
  passwordChangePhone,
  profileChange,
  agreementsUpdate,
  sbbolPremiumKm,
  bizAdaptation,
}
