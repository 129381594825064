import { media, Paragraph, Title } from '@dasreda/components'
import styled, { css } from 'styled-components'

export const SAgreementsWrap = styled.div(
  () => css`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 496px;
    padding: 155px 0;
    text-align: center;

    ${media.xl} {
      padding: 220px 0;
    }

    ${media.xs} {
      width: 100%;
      padding: 56px 24px;
    }
  `
)

export const SAgreementsTitle = styled(Title)(
  () => css`
    margin-bottom: 16px;
  `
)

export const SAgreementsText = styled(Paragraph)(
  ({ theme }) => css`
    margin-bottom: 8px;
    color: ${theme.palette.textGray};
  `
)

export const SAgreementsLink = styled.a(
  ({ theme }) => css`
    display: flex;
    width: 432px;
    margin-top: 24px;
    font: ${theme.fontSystem.middle};

    ${media.xl} {
      font: ${theme.fontSystem.large};
    }

    ${media.xs} {
      width: 100%;
    }

    span {
      width: 360px;
      text-align: left;

      ${media.xs} {
        width: 100%;
      }
    }
  `
)

export const SAgreementsIconWrap = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    min-width: 48px;
    height: 48px;
    margin-right: 24px;
    background-color: ${theme.palette.emeraldBg};
    border-radius: ${theme.radius.circle};

    svg {
      position: relative;
      top: unset;
      right: unset;
      bottom: unset;
      left: unset;
      width: 24px;
      height: 24px;
      transform: none;
      fill: ${theme.palette.emerald};
    }
  `
)
