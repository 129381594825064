import { FC, useCallback, useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import ym from 'react-yandex-metrika'
import find from 'lodash/find'
import get from 'lodash/get'
import merge from 'lodash/merge'

import { configs } from 'eco-api'

import { ReactComponent as LogoIcon } from 'assets/svg/logo.svg'
import { SENDINGS_AGREEMENT } from 'configs/agreements'
import { DATALAYER_CUSTOM_EVENT, DATALAYER_EVENT_AUTHORIZATION } from 'configs/dataLayer'
import { getUserAgreements } from 'utils/agreements'
import { request } from 'utils/api'
import { getPersonalData, logout, setAuthData } from 'utils/auth'
import { sendNewUserToGTR } from 'utils/getResponse'
import { redirect } from 'utils/redirect'
import { dataLayerHandler, sendDataLayer } from 'utils/sendDataLayer'
import { shouldUseYandexMetrika } from 'utils/yandexMetrika'

import { isValidUrl } from '../../utils/isValidUrl'

import { SEmailConfirmationLogo, SEmailConfirmationTitle, SEmailConfirmationWrapper } from './styled.index'

const { resolvePortalUrl } = configs

const messagesConfig = {
  success: {
    confirmMessage: 'Поздравляем, ваш адрес электронной почты подтвержден!',
    additionalMessage: 'Теперь вы можете войти в систему под своим логином и паролем',
    redirectUrl: '/login',
  },
  // возможно данную логику стоит пересмотреть с бизнесом
  // и предлагать пользователю попробовать еще раз зарегистрироваться/войти
  error: {
    confirmMessage: 'Извините, не удалось подтвердить ваш адрес электронной почты',
    additionalMessage: '',
    redirectUrl: `${resolvePortalUrl()}learn/video/300`,
  },
}

type TCode = keyof typeof messagesConfig

type TRouterParams = {
  uin: string
  id: string
}

const EmailConfirmation: FC<RouteComponentProps<TRouterParams>> = ({ match, history, location }) => {
  const [confirmMessage, setConfirmMessage] = useState('')
  const [additionalMessage, setAdditionalMessage] = useState('')

  const finallyCallBack = useCallback(
    (code: TCode) => {
      const { confirmMessage: confirm, additionalMessage: additional, redirectUrl } = messagesConfig[code]
      let targetUrl = location.search.split('?target=')[1]

      if (!isValidUrl(targetUrl)) {
        targetUrl = redirectUrl
      }

      setConfirmMessage(confirm)
      setAdditionalMessage(additional)

      setTimeout(() => {
        redirect({ url: targetUrl, push: history.push })
      }, 3000)
    },
    [history]
  )

  const sendData = useCallback(async () => {
    const {
      params: { uin, id },
    } = match

    if (uin && id) {
      try {
        const res = await request({
          location: `core-router/v2:register/confirm/email/${id}/${uin}`,
          method: 'get',
        })

        setAuthData(res)
        const user = get(res, 'data', {})
        const { id: userId, token } = user

        if (userId && shouldUseYandexMetrika()) {
          ym('setUserID', userId)
          ym('userParams', {
            UserID: userId,
          })

          sendDataLayer({
            userId,
          })
        }

        if (token) {
          const personalData = await getPersonalData(id)

          const agreements = await getUserAgreements()

          const { data } = {
            data: merge({ ...user, target: null }, (personalData || {}).data, { agreements: (agreements || {}).data }),
          }

          if (data.id) {
            dataLayerHandler({
              event: DATALAYER_CUSTOM_EVENT,
              eventCategory: DATALAYER_EVENT_AUTHORIZATION,
              eventAction: data.channelId || 'null',
              userId: data?.id,
            })
          }

          const sendingsAgree = find(data?.agreements, { type: SENDINGS_AGREEMENT }) || {}

          if (sendingsAgree.agree) {
            await sendNewUserToGTR(data)
          }
        }
        /** Записывать данные с помощью setAuth необходимо, т.к. они нужны для выполнения запросов аналитики при первой
         * авторизации пользователя. В конце вызываем logout для того, чтобы не происходил автологин. */
        logout()
        finallyCallBack('success')
      } catch (e) {
        finallyCallBack('error')
      }
    }
  }, [match, finallyCallBack])

  useEffect(() => {
    sendData()
  }, [sendData])

  return (
    <SEmailConfirmationWrapper>
      <SEmailConfirmationLogo>
        <LogoIcon width={60} height={60} />
      </SEmailConfirmationLogo>

      <SEmailConfirmationTitle level={3}>{confirmMessage}</SEmailConfirmationTitle>
      <SEmailConfirmationTitle level={4}>{additionalMessage}</SEmailConfirmationTitle>
    </SEmailConfirmationWrapper>
  )
}

export default EmailConfirmation
