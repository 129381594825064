import { TRequest } from 'types/api'

import { Header } from 'components'
import { TFormConfig } from 'containers/FormBuilder'
import { TValues } from 'containers/FormBuilder/components/Field'
import { SFieldInput } from 'containers/FormBuilder/styled.index'
import { request } from 'utils/api'
import { redirect } from 'utils/redirect'
import { equal, passwordStrongValidator, required } from 'utils/validators'

import { passwordFieldProps, passwordRepeatFieldProps } from '../../defaultFieldsProps'

// TODO: продумать ошибку со скрытым полем uuid
const passwordChange: TFormConfig = {
  title: 'Смена пароля',
  header: <Header type="password-change" />,
  submitButtonText: 'Сохранить новый пароль',
  fields: [
    {
      name: 'email',
      type: 'hidden',
      component: SFieldInput,
      qa: 'password_uuid_field',
    },
    {
      name: 'code',
      type: 'hidden',
      component: SFieldInput,
      qa: 'password_uuid_field',
    },
    {
      ...passwordFieldProps,
      validators: [required, passwordStrongValidator],
    },
    {
      ...passwordRepeatFieldProps,
      validators: [
        required,
        passwordStrongValidator,
        (value, allValues: TValues) => equal(value, allValues, 'password'),
      ],
    },
  ],
  successMessage: 'Вы успешно сменили пароль',
  onRequest: (values) => {
    const config: TRequest = {
      location: 'core-router/v2:user/restore/email/confirm',
      method: 'post',
      params: {
        data: values,
      },
    }

    return request(config)
  },

  postHook: (_error, _response, { initialValues: { email }, history: { push } }) => {
    redirect({ push, url: `/login?username=${email}` })
  },
}

export default passwordChange
