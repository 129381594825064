import { useCallback } from 'react'
import { useMutation } from '@apollo/client'
import { loader } from 'graphql.macro'

import { IThankYouProps } from './types'

import { useSecondsTimer } from '../../hooks/useSecondsTimer'

import { parseEmailProvider } from '../../utils/parseEmailProvider'
import { redirect } from '../../utils/redirect'

const repeatSendConfirmationMailGql = loader('src/gql/mutations/repeatSendConfirmationMail.gql')

export const useBase = (props: IThankYouProps) => {
  const {
    location: { state },
    history: { push },
  } = props

  const email = state?.userEmail || ''

  const redirectToAuth = useCallback(() => redirect({ push, url: '/' }), [])

  if (!email) {
    redirectToAuth()
  }

  const [seconds, isTimeOver, resetTimer] = useSecondsTimer(60)

  const [sendMail, { loading, data }] = useMutation(repeatSendConfirmationMailGql)

  const isAttemptsOver = data?.repeatSendConfirmationMail === 403
  const isAlreadyConfirmed = data?.repeatSendConfirmationMail === 409

  if (isAlreadyConfirmed) {
    redirect({ push, url: `/?isAlreadyConfirmed=true` })
  }

  const repeatSendConfirmation = async () => {
    await sendMail({
      variables: { email },
    })

    resetTimer()
  }

  const emailDomain = email.substr(email.indexOf('@') + 1)
  const emailProvider = parseEmailProvider(emailDomain)

  return { repeatSendConfirmation, seconds, isTimeOver, loading, emailProvider, isAttemptsOver, redirectToAuth }
}
