import { FC } from 'react'

import { ISocialProviderBtn } from './types'

const SocialProviderBtn: FC<ISocialProviderBtn> = ({ src, alt }) => (
	<button type="button">
		<img src={src} alt={alt} />
	</button>
)

SocialProviderBtn.propTypes = {}

export default SocialProviderBtn
