import { request } from './api'

const getClientId = async (product?: string) => {
  if (product) {
    const { data } = await request({
      location: `uni-products/v1:prices/code/${product}`,
      method: 'get',
    })

    return data?.clientId
  }

  return null
}

export default getClientId
