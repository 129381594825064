import { FC } from 'react'
import { Timer } from '@dasreda/components'

import { ISubmitBlock } from './types'

import SubmitButton from 'containers/FormBuilder/components/SubmitButton'

import useBase from './useBase'

const SubmitBlock: FC<ISubmitBlock> = (props) => {
  const { submitting, hasValidationErrors } = props
  const { bruteforceProtectIsActive, bruteforceSecondsLeft } = useBase(props)

  return (
    <SubmitButton type="submit" disabled={submitting || hasValidationErrors || bruteforceProtectIsActive}>
      {!bruteforceProtectIsActive && 'Войти'}
      {bruteforceProtectIsActive && bruteforceSecondsLeft > 0 && <Timer seconds={bruteforceSecondsLeft} />}
    </SubmitButton>
  )
}

export default SubmitBlock
