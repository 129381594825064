import { TRequest } from 'types/api'

import { configs } from 'eco-api'

const { axios, getApiUrl } = configs

export const request = ({ location, params = {}, method = 'post' }: TRequest) => {
  // TODO: есть бага в getApiUrl для такого url функция определяет окружение, как DEV
  // кейс тестовый, но запутывает
  // https://auth.uat.dasreda.ru/login?type=course_access&redirect=https://localhost:3000/learn/courses/kak-rabotat-s-cenami
  const config = { url: getApiUrl(location), method, ...params }
  return axios().request(config)
}
