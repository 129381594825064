import React from 'react'

import { PUBLISH_PD, USER_AGREEMENT } from 'configs/agreements'

const agreementsLabel = (type, link) => {
  let labelText = 'Я принимаю'
  let linkText = 'условия'

  switch (type) {
    case PUBLISH_PD:
      labelText = 'Я даю'
      linkText = 'согласие на обработку персональных данных'
      break
    case USER_AGREEMENT:
      labelText = 'Принимаю'
      linkText = 'условия использования'
      break
    default:
      labelText = 'Я принимаю'
      linkText = 'условия'
  }

  return (
    <span>
      {`${labelText} `}
      <a target="_blank" rel="noopener noreferrer" href={link}>
        {linkText}
      </a>
    </span>
  )
}

export default agreementsLabel
