import getApolloClient from 'gql/helpers/getApolloClient'
import { loader } from 'graphql.macro'

const checkPhoneExist = loader('src/gql/queries/checkPhoneExist.gql')

/**
 * Проверка существования зарегистрированного номера телефона на портале Деловая Среда
 * @param {String} phone Телефон
 * @return {Promise<{isPhoneExist: boolean, err: null}|{isPhoneExist: null, err}>}
 */
export const checkIsPhoneExist = async (phone) => {
  try {
    const apolloClient = await getApolloClient
    const phoneCheckRequest = await apolloClient.query({
      query: checkPhoneExist,
      variables: { phone: String(phone).replace(/[^\d]/g, '') },
      fetchPolicy: 'network-only',
    })

    return {
      isPhoneExist: !!phoneCheckRequest?.data?.checkPhone?.exist,
      err: null,
    }
  } catch (e) {
    console.error(e)

    return {
      isPhoneExist: null,
      err: e,
    }
  }
}
