import { NavLink } from 'react-router-dom'
import { media } from '@dasreda/components'
import styled, { css } from 'styled-components'

import { IFormFooterProps } from './types'

const getFlexAlign = (align: string | undefined) => {
  switch (align) {
    case 'center':
      return 'center'
    case 'left':
      return 'flex-start'
    case 'right':
      return 'flex-end'
    default:
      return 'center'
  }
}

export const SFormFooter = styled.div<IFormFooterProps>(
  ({ align }) => css`
    display: flex;
    justify-content: ${getFlexAlign(align)};
    width: var(--form-width, 400px);
    margin-top: 8px;
    margin-bottom: 8px;

    ${media.xs} {
      width: 100%;
    }
  `
)

export const SFormFooterLink = styled(NavLink)(
  ({ theme }) => css`
    color: ${theme.palette.textGray};
  `
)

export const SFormFooterLinkGreen = styled(NavLink)(
  ({ theme }) => css`
    margin-top: 16px;
    margin-right: 12px;
    color: ${theme.palette.emerald};
    font: ${theme.fontSystem.middle};

    ${media.md} {
      font: ${theme.fontSystem.large};
    }
  `
)

export const SDsAgree = styled.div(
  ({ theme }) => css`
    color: ${theme.palette.textGray};
    font: ${theme.font.desktop.p3};
    text-align: center;

    ${media.xs} {
      width: 100%;
    }
  `
)
