import { FC } from 'react'

import { Agreements } from 'components'

export const listAgreements = [
  {
    title: 'Пользовательское соглашение АО «Деловая среда»',
    href: 'https://cdn.dasreda.ru/fonts-static/polzovatelskoe_soglashenie.pdf',
  },
  {
    title:
      'Согласие АО «Деловая среда» на обработку моих персональных данных, в соответствии с Федеральным законом от 27.07.2006 года №152-ФЗ «О персональных данных»',
    href: 'https://cdn.dasreda.ru/fonts-static/soglasie-pd.pdf',
  },
  {
    title: 'Политикой конфиденциальности АО «Деловая среда»',
    href: 'https://cdn.dasreda.ru/fonts-static/company-policy.pdf',
  },
]

const AgreementsMSP: FC = () => <Agreements list={listAgreements} />

export default AgreementsMSP
