/* eslint no-useless-escape: 0 */

const email = (value) => {
  const regex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return regex.test(String(value).toLowerCase())
}

const passwordStrong = (value) => value.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{6,64}$/)
const passwordSimple = (value) => value.match(/^(?!.*(?:[а-яА-ЯёЁ]))(?=.*\d)(?=.*[a-zA-Z]).{6,64}$/)

const number = (value) => value.match(/^\d+$/)

const phone = (value) => {
  const regex = /\+?7\s?\(?\d{3}\)?\s?\d{3}-?\d{4}/
  return regex.test(String(value).toLowerCase())
}

const inn = (value) => {
  const notValidInn = ['0000000000', '000000000000']
  if (value.match(/\D/) || notValidInn.indexOf(value) !== -1) return false

  const innValue = String(value)

  if (innValue.length === 10) {
    return (
      innValue[9] ===
      String(
        ((2 * innValue[0] +
          4 * innValue[1] +
          10 * innValue[2] +
          3 * innValue[3] +
          5 * innValue[4] +
          9 * innValue[5] +
          4 * innValue[6] +
          6 * innValue[7] +
          8 * innValue[8]) %
          11) %
          10
      )
    )
  }
  if (innValue.length === 12) {
    return (
      innValue[10] ===
        String(
          ((7 * innValue[0] +
            2 * innValue[1] +
            4 * innValue[2] +
            10 * innValue[3] +
            3 * innValue[4] +
            5 * innValue[5] +
            9 * innValue[6] +
            4 * innValue[7] +
            6 * innValue[8] +
            8 * innValue[9]) %
            11) %
            10
        ) &&
      innValue[11] ===
        String(
          ((3 * innValue[0] +
            7 * innValue[1] +
            2 * innValue[2] +
            4 * innValue[3] +
            10 * innValue[4] +
            3 * innValue[5] +
            5 * innValue[6] +
            9 * innValue[7] +
            4 * innValue[8] +
            6 * innValue[9] +
            8 * innValue[10]) %
            11) %
            10
        )
    )
  }

  return false
}

export default { email, passwordStrong, passwordSimple, inn, phone, number }
