import { FC } from 'react'
import { NavLink } from 'react-router-dom'

const Error404: FC = () => (
	<div>
		<h1>Страница не найдена</h1>
		<p>
			Неправильно набран адрес, или такой страницы на сайте больше не существует. Вернуться на{' '}
			<NavLink to="/">главную страницу</NavLink>.
		</p>
	</div>
)

export default Error404
