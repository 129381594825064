import { Paragraph } from '@dasreda/components'
import styled, { css } from 'styled-components'

import { SProviderIconProps } from './types'

export const SProviderWrapper = styled.div(
  () => css`
    display: flex;
    flex-direction: column;
    text-align: center;
  `
)

export const SProviderIntro = styled(Paragraph)(
  () => css`
    text-align: center;
  `
)

export const SProviderAccess = styled.div(
  () => css`
    margin-bottom: 24px;
  `
)

export const SProviderScope = styled.div(
  () => css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 24px;
    text-align: left;

    &:last-child {
      padding-bottom: 0;
    }
  `
)

export const SProviderScopeTitle = styled.div(
  () => css`
    margin-bottom: 16px;
  `
)

export const SProviderScopeField = styled.div(
  () => css`
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  `
)

export const SProviderScopeFieldIcon = styled.div<SProviderIconProps>(
  ({ iconUrl }) => css`
    display: block;
    width: 48px;
    height: 48px;
    margin-right: 16px;
    background: url(${iconUrl}) 50% 50% no-repeat #eef9ec;
    border-radius: 100%;
  `
)
