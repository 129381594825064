import getApolloClient from 'gql/helpers/getApolloClient'
import { loader } from 'graphql.macro'

const checkInnExist = loader('src/gql/queries/checkInnExist.gql')

/**
 * Проверка существования зарегистрированного ИНН в MSP
 * @param {String} inn ИНН
 * @return {Promise<{isInnExist: boolean, err: null}|{isInnExist: null, err}>}
 */
export const checkIsInnExist = async (inn) => {
  try {
    const apolloClient = await getApolloClient
    const phoneCheckRequest = await apolloClient.query({
      query: checkInnExist,
      variables: { inn },
      fetchPolicy: 'network-only',
    })

    return {
      isInnExist: !!phoneCheckRequest?.data?.checkInn?.exist,
      err: null,
    }
  } catch (e) {
    console.error(e)

    return {
      isInnExist: null,
      err: e,
    }
  }
}
