import get from 'lodash/get'

import { configs } from 'eco-api'

export function shouldUseYandexMetrika() {
  const localEnv = get(process.env, 'NODE_ENV')
  // const serverEnv = configs.resolveEnvironment()
  // do not show on localhost
  if (localEnv === 'development') return false
  return true
}

export function getYandexMetrikaCounters() {
  const serverEnv = configs.resolveEnvironment()
  const localEnv = get(process.env, 'NODE_ENV')

  const env = localEnv === 'development' && serverEnv === 'dev' ? 'local' : serverEnv

  const counters = {
    local: [44518951],
    dev: [47481796],
    uat: [47860742],
    uat2: [47860742],
    prod: [47481808],
  }

  return counters[env] || null
}
