import { TResUser } from 'types/user'

import { Header } from 'components'
import { TFormConfigFn } from 'containers/FormBuilder'
import { login } from 'utils/auth'
import { email as emailValidator, passwordSimpleValidator, required } from 'utils/validators'

import { emailFieldProps, passwordFieldProps } from '../../defaultFieldsProps'

const loginFrame: TFormConfigFn = () => ({
  title: 'Войти',
  submitButtonText: 'Войти',
  header: <Header type="login" />,
  withLogo: true,
  fields: [
    {
      ...emailFieldProps,
      validators: [required, emailValidator],
      fieldSuffix: 'loginDefault',
    },
    {
      ...passwordFieldProps,
      validators: [required, passwordSimpleValidator],
    },
  ],
  onRequest: ({ password, username }) => {
    const config = {
      location: 'single-auth:login',
      params: {
        withCredentials: true,
        data: { password, username, setCookie: true },
      },
    }

    return login(config)
  },
  postHook: async (_, response) => {
    if (response) window.parent.postMessage((response as TResUser).data, '*')
  },
})

export default loginFrame
