import { useState } from 'react'
import axios from 'axios'
import { FORM_ERROR } from 'final-form'

import KmRegistrationButtons from 'components/KmRegistrationButtons'
import { commonError } from 'configs/consts'
import { TFormConfig } from 'containers/FormBuilder'
import SubmitButton from 'containers/FormBuilder/components/SubmitButton'
import { SFieldInput } from 'containers/FormBuilder/styled.index'
import { request } from 'utils/api'
import { required } from 'utils/validators'

import { useSecondsTimer } from '../../../../hooks/useSecondsTimer'

import { SKmSbscrConfirmCode, SKmSbscrSubmit } from '../../styled.index'

import { getParams, getSubmitFn, internalRegRedirect, postRender } from './helpers'
import { useShortUrlSender } from './hooks'

const MAX_ATTEMPTS_NUMBER = 3

const SubmitBlock: TFormConfig['submitBlock'] = ({
  submitting,
  hasValidationErrors,
  submitSucceeded,
  form,
  history: { push },
}) => {
  const sendKmSubscriptionShortUrl = useShortUrlSender()
  const sendMessage = getSubmitFn(form, sendKmSubscriptionShortUrl)
  const { 'repeat-сode': repeatCode } = getParams()
  const [currentAttemptNumber, setCurrentAttemptNumber] = useState(0)
  const [attemptSeconds, attemptTimerIsOver, resetAttemptTimer] = useSecondsTimer()
  const handleInternalSubmit = async () => {
    await sendMessage()
    internalRegRedirect({ push })
  }
  const attemptToGetCode = async () => {
    setCurrentAttemptNumber(currentAttemptNumber + 1)
    await request({
      location: `core-router/v2:register/repeat/${repeatCode}`,
      method: 'get',
    })
    resetAttemptTimer()
  }
  const withTimer = !submitting && !submitSucceeded
  const attemptsIsOver = currentAttemptNumber >= MAX_ATTEMPTS_NUMBER

  return (
    <SKmSbscrSubmit>
      {withTimer && !attemptsIsOver && !attemptTimerIsOver && (
        <SKmSbscrConfirmCode>Отправить код повторно через 0:{`${attemptSeconds}`.padStart(2, '0')}</SKmSbscrConfirmCode>
      )}
      {withTimer && !attemptsIsOver && attemptTimerIsOver && (
        <SubmitButton type="button" color="textGray" onClick={attemptToGetCode}>
          Отправить код повторно
        </SubmitButton>
      )}
      {withTimer && attemptsIsOver && <SKmSbscrConfirmCode>Вы исчерпали все попытки</SKmSbscrConfirmCode>}
      <KmRegistrationButtons
        onSubmitInternal={handleInternalSubmit}
        onSubmitMessage={sendMessage}
        submitting={!!submitting}
        hasValidationErrors={!!hasValidationErrors}
      />
    </SKmSbscrSubmit>
  )
}

const kmSubscriptionNewUser: TFormConfig = {
  title: 'Регистрация нового пользователя Деловая Среда «Премиум»',
  submitBlock: SubmitBlock,
  fields: [
    {
      name: 'confirmNumber',
      type: 'password',
      component: SFieldInput,
      placeholder: 'Код из сообщения',
      validators: [required],
      onKeyPress: (e) => e.key === 'Enter' && e.preventDefault(),
    },
  ],
  postRender,
  successMessage: 'Проверьте ваш телефон или почту',
  onRequest: async (values) => {
    const { confirmNumber } = values
    const params = getParams()
    const { id } = params

    try {
      await request({
        location: `core-router/v2:register/confirm/phone/${id}/${confirmNumber}`,
        method: 'get',
      })
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 400) {
        throw new Error('Неверный код')
      }
      throw new Error((error as Error).message)
    }

    return true
  },
  postHook: async (error) => {
    if (error && (error as Error).message) {
      return { [FORM_ERROR]: (error as Error).message }
    }

    return commonError
  },
}

export default kmSubscriptionNewUser
