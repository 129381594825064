import { NavLink } from 'react-router-dom'
import { Button, Checkbox, Input, media, Title } from '@dasreda/components'
import styled, { css } from 'styled-components'

import { TVerticalAlign } from './components/Form'

interface SFieldWrapperProps {
  $type?: string
}

interface SFormWrapperProps {
  $verticalAlign: TVerticalAlign
  $hasLeftColumn?: boolean
}

export const SFormWrapper = styled.div<SFormWrapperProps>(
  ({ $hasLeftColumn, $verticalAlign }) => css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;

    ${$verticalAlign === 'center' &&
    css`
      justify-content: center;
      margin-top: 60px;
      margin-bottom: ${$hasLeftColumn ? '20px' : '110px'};
    `}

    ${$verticalAlign === 'flex-start' &&
    css`
      justify-content: flex-start;
      margin: 120px 0;
    `}

    ${$hasLeftColumn &&
    css`
      ${media.md} {
        flex-flow: row;
      }
      ${media.xxl} {
        transform: translateX(-54px);
      }
    `}

		${media.xs} {
      padding: 0 24px;
    }
  `
)

export const SFormTitle = styled(Title)(
  () => css`
    width: var(--form-width, 400px);
    text-align: center;

    ${media.xs} {
      width: 100%;
    }
  `
)

export const SLeftColumn = styled.div(
  () => css`
    padding-right: 0;

    ${media.lessThan('lg')} {
      display: none;
    }

    ${media.lg} {
      max-width: 380px;
      margin-right: 76px;
    }

    ${media.xl} {
      max-width: 520px;
      margin-right: 175px;
    }

    ${media.xxl} {
      max-width: 580px;
      margin-right: 223px;
    }
  `
)

export const SForm = styled.form(
  () => css`
    width: var(--form-width, 400px);
    margin-top: 24px;

    ${media.xl} {
      margin-top: 32px;
    }

    ${media.xs} {
      width: 100%;
    }
  `
)

export const SFormExpand = styled(NavLink)(
  ({ theme }) => css`
    display: block;
    width: 100%;
    font: ${theme.fontSystem.middle};
    text-align: center;

    ${media.xl} {
      font: ${theme.fontSystem.large};
    }
  `
)

export const SFieldsBlock = styled.div``

export const SFieldWrapper = styled.div<SFieldWrapperProps>(
  ({ $type }) => css`
    display: flex;
    margin-bottom: ${$type === 'hidden' ? '0px' : '24px'};

    &:last-child {
      margin-bottom: 0;
    }
  `
)

export const SFieldInput = styled(Input)`
  width: 100%;

  &.withSuffix input {
    padding-right: 90px;
  }
`

export const SCheckboxInput = styled(Checkbox)(
  ({ theme }) => css`
    width: 100%;
    margin-top: 4px;
    margin-bottom: 8px;

    label {
      height: auto;
      margin-bottom: 0;

      > div {
        position: relative;
        flex: 28px 0 0;
      }

      > span {
        padding-left: 12px;
        color: ${theme.palette.darkTextGray};
        text-align: left;
        hyphens: none;

        a {
          color: ${theme.palette.darkTextGray};
          text-decoration: underline;
        }
      }
    }
  `
)

export const SSubmitButton = styled(Button)`
  width: 100%;
  margin-top: 24px;
`
