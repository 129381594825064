import { FC, useCallback, useEffect } from 'react'

import { ISbbolParams, ISbbolRedirect } from './types'

import {
  COURSE_LS_KEY,
  OTR_TYPE_LS_KEY, REDIRECT_URL,
  SBBOL_115FZ,
  SBBOL_120_SEC_PRODUCT,
  SBBOL_LMS,
  SBBOL_PREMIUM
} from "configs/consts"
import { request } from 'utils/api'
import getClientId from 'utils/getClientId'

const SbbolRedirect: FC<ISbbolRedirect> = ({
  location: {
    query: { product, userType = 'WEB', callbackPort, otr_type, course },
  },
}) => {
  const getParams = useCallback(async () => {
    try {
      const params: ISbbolParams = { db_schema: 'public', userType, callbackPort }

      localStorage.removeItem(REDIRECT_URL)
      localStorage.removeItem(OTR_TYPE_LS_KEY)
      localStorage.removeItem(COURSE_LS_KEY)

      if (otr_type) {
        localStorage.setItem(OTR_TYPE_LS_KEY, otr_type)
      }

      if (course) {
        localStorage.setItem(COURSE_LS_KEY, course)
      }

      if ([SBBOL_120_SEC_PRODUCT, SBBOL_PREMIUM, SBBOL_115FZ, SBBOL_LMS].includes(product)) {
        params.clientId = await getClientId(product)
      }

      const res = await request({
        location: 'single-auth:sbbol/generate-url',
        method: 'get',
        params: { params },
      })

      window.location = res.data
    } catch (error) {
      console.info(error)
    }
  }, [product])

  useEffect(() => {
    getParams()
  }, [getParams])

  return <div />
}

export default SbbolRedirect
