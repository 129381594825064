import { ComponentType, FC, HTMLAttributes, ReactElement, useState } from 'react'
import { Field, FieldProps } from 'react-final-form'
import { useGrid } from '@dasreda/components'
import { FieldSubscription, FieldValidator } from 'final-form'
import formatString from 'format-string-by-pattern'
import set from 'lodash/set'

import { TAny } from '@dasreda/types'

import { masks } from 'utils/masks'

import { ICaptchaInfo } from '../../index'
import { SFieldWrapper } from '../../styled.index'
import { FieldSuffixWithState, suffixTypes } from '../FieldSuffix'

/* eslint-disable @typescript-eslint/no-explicit-any */
export type TValues = { [key: string]: TAny }

type TExtraFieldsParams<FieldValue = TAny> = Partial<
  Pick<FieldProps<FieldValue, TAny>, 'validate' | 'parse' | 'format'>
>

type TValidator<FieldValue = TAny> = FieldValidator<FieldValue>
/* eslint-enable @typescript-eslint/no-explicit-any */

interface IFieldRender {
  name: string
  hidden?: boolean
  label?: ReactElement | string
  type?: string
  disabled?: boolean
  placeholder?: string
  className?: string
  // Отвечает за отображение элемента
  component: ComponentType<TAny>
  // HOC, позволяющий добавить полю доп. функциональность (например, иконка позволяющая посмотреть пароль)
  wrapper?: ComponentType
  // Функция валидирующая значение поля (общий набор - /src/utils/validators.js)
  validators?: TValidator[]
  // Функция преобразующая значение поля под заданный формат (общий набор - /src/utils/normalize.js)
  mask?: keyof typeof masks
  // Позиция поля в списке, может быть явно задано, для полей,
  // которые добавляются взависимсости от внешних условий (например, query type)
  index?: number
  // Фокус на поле по умолчанию
  focus?: boolean
  // На какие изменения подписывается поле
  subscription?: FieldSubscription
  hasSeparator?: boolean
  qa?: string
  autoComplete?: string
  fieldSuffix?: keyof typeof suffixTypes
  resetCaptcha?: {
    captchaInfo: ICaptchaInfo
    setCaptchaInfo: (value: ICaptchaInfo) => void
  }
  size?: 'small' | 'medium' | 'large'
}

export type TField = HTMLAttributes<HTMLInputElement | HTMLSelectElement> &
  IFieldRender &
  Pick<TExtraFieldsParams, 'parse'>

type TComposeValidators = (validators: TValidator[]) => TValidator

const composeValidators: TComposeValidators = (validators) => (value, allValues) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const validateFn of validators) {
    const error = validateFn(value, allValues)
    if (error) return error
  }

  return undefined
}

const FieldRender: FC<TField> = ({
  name,
  label = null,
  validators = [],
  placeholder = null,
  component: Component,
  mask: maskName,
  type = '',
  wrapper: Wrapper = null,
  parse,
  index: key,
  focus = null,
  subscription = undefined,
  qa = '',
  fieldSuffix = undefined,
  ...fieldProps
}) => {
  const extraFieldParams: TExtraFieldsParams = {}
  const media = useGrid()

  if (validators) extraFieldParams.validate = composeValidators(validators)
  if (parse) extraFieldParams.parse = parse
  if (maskName && masks[maskName]) {
    extraFieldParams.format = (value: string) => {
      if (!value) return ''
      return formatString(masks[maskName])(value)
    }
  }

  return (
    <Field name={name} subscription={subscription} {...extraFieldParams} key={name} type={type}>
      {({ input: defaultFieldProps, meta: { touched, pristine, error, submitError, submitSucceeded } }) => {
        const props = {
          ...defaultFieldProps,
          ...fieldProps,
          id: `${name}-${key}`,
          'data-qa': qa,
          type,
          placeholder,
          label,
        }

        if (fieldSuffix) {
          if (suffixTypes[fieldSuffix].withState !== undefined) {
            const [suffixState, setSuffixState] = useState(false)

            set(
              props,
              'suffix',
              <FieldSuffixWithState
                defaultComponent={suffixTypes[fieldSuffix].withState?.default.component}
                activeComponent={suffixTypes[fieldSuffix].withState?.active.component}
                suffixState={suffixState}
                setSuffixState={setSuffixState}
                timeout={suffixTypes[fieldSuffix].withState?.timeout}
              />
            )

            set(
              props,
              suffixTypes[fieldSuffix].withState?.default.prop?.propName || '',
              suffixState
                ? suffixTypes[fieldSuffix].withState?.active.prop?.propValue
                : suffixTypes[fieldSuffix].withState?.default.prop?.propValue
            )
          } else {
            set(props, 'suffix', suffixTypes[fieldSuffix].component)
          }
          set(props, 'className', 'withSuffix')
        }

        const FieldComponent = (changedProps: TAny) => (
          <Component
            {...props}
            {...changedProps}
            autoFocus={!!focus?.toString()}
            error={touched && !(pristine && submitSucceeded) && (error || submitError)}
            hint={touched && !(pristine && submitSucceeded) && (error || submitError)}
            inputSize={props.size ?? (media.xlOrMore ? 'large' : 'medium')}
          />
        )

        return (
          <SFieldWrapper $type={type}>
            {Wrapper ? <Wrapper>{FieldComponent}</Wrapper> : FieldComponent({})}
          </SFieldWrapper>
        )
      }}
    </Field>
  )
}

export default FieldRender
