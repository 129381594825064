import { FC, useCallback, useEffect, useState } from 'react'
import { useGrid } from '@dasreda/components'
import { SberId as SberIdIcon } from '@dasreda/icons'
import axios from 'axios'

import { request } from 'utils/api'
import { SberidDeeplink } from 'utils/external/sberid-deeplink'

import { SSberIdButton } from './styled.index'

interface ISberId {
  withDeepLink?: boolean
}

const { CancelToken } = axios

const SberId: FC<ISberId> = ({ withDeepLink = true }) => {
  const cancelTokenSource = CancelToken.source()
  const media = useGrid()

  const [, setStatus] = useState<boolean>(false)
  const [url, setUrl] = useState<string>('#loading')

  const generateDeepLink = useCallback(
    (sberOriginUrl) => {
      const callback = ({ link }: { link: string }) => {
        setUrl(link)
        setStatus(true)
      }

      try {
        // eslint-disable-next-line no-new
        new SberidDeeplink({
          params: sberOriginUrl.split('?')[1],
          callback,
          needAdditionalRedirect: true,
        })
      } catch (error) {
        console.error(error)
      }
    },
    [setUrl, setStatus]
  )

  const generateLink = useCallback(async () => {
    try {
      const res = await request({
        location: 'single-auth:sbol/generate-url',
        method: 'get',
        params: {
          cancelToken: cancelTokenSource.token,
        },
      })

      const { data: sberOriginUrl } = res

      if (withDeepLink) {
        generateDeepLink(sberOriginUrl)
      } else {
        setUrl(sberOriginUrl)
        setStatus(true)
      }
    } catch (error) {
      console.error(error)
    }
  }, [withDeepLink, generateDeepLink, setUrl, setStatus])

  useEffect(() => {
    generateLink()

    return () => cancelTokenSource.cancel()
  }, [generateLink])

  return (
    <SSberIdButton
      to={url}
      type="button"
      color="textGraphite"
      size={media.xlOrMore ? 'large' : 'medium'}
      icon={<SberIdIcon />}
      iconPosition="left"
    >
      Войти по Сбер ID
    </SSberIdButton>
  )
}

export default SberId
