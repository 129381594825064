import { FC } from 'react'

const Error: FC = ({ children }) => (
  <>
    <h1>Не удалось войти</h1>
    <p>Возможно вы:</p>
    <ul>
      <li>— Использовали режим инкогнито</li>
      <li>— Начали вход с помощью браузера, встроенного в другое приложение</li>
    </ul>

    {children && (
      <>
        <p>Попробуйте войти еще раз из этой вкладки</p>
        {children}
      </>
    )}
  </>
)

export default Error
