import styled, { css } from 'styled-components'

export const SAppWrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    background-color: ${theme.palette.white};
  `
)
