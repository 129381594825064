import { createBrowserHistory } from 'history'

import { parseQueryString } from 'utils/parseQueryString'

const history = createBrowserHistory()

const addLocationQuery = () => {
  const search = history.location.search.replace('?', '')

  // TODO: update "history" dependency
  // Problem: can't extend history.location
  history.location = Object.assign(history.location, { query: parseQueryString(search) })
}

addLocationQuery()

history.listen(() => {
  addLocationQuery(history)
})

export default history
