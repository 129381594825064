import DemoAccess from './DemoAccess'
import SbbolId from './SbbolId'
import SberId from './SberId'
import SocialProviderBtn from './SocialProviderBtn'

export const btnsConfig = {
  social: {
    vk: (
      <SocialProviderBtn
        src="https://cdn.dasreda.ru/photo-data/e5796377-92e4-46af-bdf3-fed0bdd227c5/vk-icon.svg"
        alt="Войти через Вконтакте"
      />
    ),
    facebook: (
      <SocialProviderBtn
        src="https://cdn.dasreda.ru/photo-data/3e3dbc3d-ae37-4014-9964-78522de71700/facebook-icon.svg"
        alt="Войти через Фейсбук"
      />
    ),
    insta: (
      <SocialProviderBtn
        src="https://cdn.dasreda.ru/photo-data/f2ceb87d-758f-4076-8d48-03528c21f0dd/insta-icon.svg"
        alt="Войти через Инстаграм"
      />
    ),
  },
  other: {
    sberId: SberId,
    sberBusinessId: SbbolId,
    demoAccess: DemoAccess,
  },
}
