import { Footer } from 'components'
import agreementsLabel from 'configs/agreementsLabel'
import { TFormConfigFn, TPreparedAgreeDocuments } from 'containers/FormBuilder'
import { TField } from 'containers/FormBuilder/components/Field'
import { SCheckboxInput } from 'containers/FormBuilder/styled.index'
import { updateUserAgreementsRequest } from 'utils/agreements'
import { redirect } from 'utils/redirect'
import { required } from 'utils/validators'

import agreementsList from '../../agreements'

// TODO: move to utils/agreement
type TAgreement = {
  type: string
  agreed: boolean
  docDate: string
}

type TAgreementLocationState = {
  agreementTypesToUpdate?: (keyof typeof agreementsList)[]
  agreementDocuments?: TPreparedAgreeDocuments
  url?: string
}

const agreementsUpdate: TFormConfigFn = (props) => {
  const {
    location: { state = {} },
    history: { push },
  } = props
  const { agreementTypesToUpdate = [], agreementDocuments = {}, url, ...restState } = state as TAgreementLocationState
  const agreements: TAgreement[] = []
  const fields: TField[] = []

  agreementTypesToUpdate.forEach((agreementType) => {
    const agreementDocument = agreementDocuments[agreementType]

    fields.push({
      name: agreementType,
      component: SCheckboxInput,
      type: 'checkbox',
      label: agreementsLabel(agreementType, agreementDocument.link),
      validators: [required],
    })

    agreements.push({
      type: agreementType,
      agreed: true,
      docDate: agreementDocument.docDate,
    })
  })

  return {
    withLogo: true,
    submitButtonText: 'Подтвердить условия',
    fields,
    insideFooter: <Footer type="agree" align="center" />,
    expanded: false,
    expand: () => {},
    onRequest: async () => {
      await updateUserAgreementsRequest(agreements)
      redirect({ push, url }, restState)
    },
  }
}

export default agreementsUpdate
