enum AppleProvider {
  'me.com',
  'icloud.com',
  'mac.com',
}
enum GoogleProvider {
  'gmail.com',
}
enum YandexProvider {
  'yandex.ru',
  'ya.ru',
}
enum MailProvider {
  'mail.ru',
}

export const parseEmailProvider = (emailDomain: string): string | null => {
  if (Object.values(AppleProvider).includes(emailDomain)) {
    return 'apple'
  }

  if (Object.values(GoogleProvider).includes(emailDomain)) {
    return 'google'
  }

  if (Object.values(YandexProvider).includes(emailDomain)) {
    return 'yandex'
  }

  if (Object.values(MailProvider).includes(emailDomain)) {
    return 'mail'
  }

  return null
}
