import { FC } from 'react'

import { ReactComponent as GmailIcon } from 'assets/svg/gmail.svg'
import { ReactComponent as IcloudIcon } from 'assets/svg/icloud.svg'
import { ReactComponent as MailIcon } from 'assets/svg/mail.svg'
import { ReactComponent as YandexIcon } from 'assets/svg/yandex.svg'

import { SMailProviderButton } from './styled.index'

interface IMailProviderButton {
  emailDomain: string
}

const getEmailProviderConfig = (emailDomain: string) => {
  switch (emailDomain) {
    case 'google':
      return { icon: <GmailIcon />, text: 'Gmail', link: 'https://mail.google.com/mail/u/1' }
    case 'apple':
      return { icon: <IcloudIcon />, text: 'Apple Mail', link: 'https://www.icloud.com/mail' }
    case 'mail':
      return { icon: <MailIcon />, text: 'Mail', link: 'https://e.mail.ru/messages/inbox' }
    case 'yandex':
      return { icon: <YandexIcon />, text: 'Яндекс Почту', link: 'https://mail.yandex.ru/' }
    default:
      return {}
  }
}

const MailProviderButton: FC<IMailProviderButton> = ({ emailDomain }) => {
  const { icon, text, link } = getEmailProviderConfig(emailDomain)

  return (
    <SMailProviderButton
      rel="noopener noreferrer"
      target="_blank"
      to={link || ''}
      type={emailDomain === 'apple' ? 'link' : 'button'}
      color="textGraphite"
      size="large"
      icon={icon}
      iconPosition="left"
    >
      <span>Перейти в {text}</span>
    </SMailProviderButton>
  )
}

export default MailProviderButton
