import { FC } from 'react'

import useBase from './useBase'

import { SAutocomplete } from './styled.index'

const City: FC = (props) => {
  const { cities, getCities } = useBase()

  return <SAutocomplete forwardedAs="div" {...props} items={cities} fetchByQuery={getCities} />
}

export default City
