export const scopesConfig = {
  read: {
    title: 'Чтению данных',
    fields: [
      {
        name: 'Публичное имя',
        iconUrl: 'https://cdn.dasreda.ru/photo-data/f1bdd189-08a5-41be-807c-5dc544b1b8ba/icon-person.svg',
      },
      {
        name: 'Номер телефона',
        iconUrl: 'https://cdn.dasreda.ru/photo-data/6e04cde6-9773-48af-a0b0-147b822b78c1/icon-phone.svg',
      },
      {
        name: 'Адрес электронной почты',
        iconUrl: 'https://cdn.dasreda.ru/photo-data/a77ab006-3383-4248-9888-54f53b13841f/icon-email.svg',
      },
    ],
  },
}
