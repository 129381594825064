import { AxiosResponse } from 'axios'
import { History, LocationState } from 'history'
import { TUser } from 'types/user'

import DEFAULT_AGREEMENT_TYPES from 'configs/agreementTypes'

import CHANNEL_IDS from 'configs/channels'
import { loadAgreementDocuments, prepareAgreementTypesToUpdateForUser, updateAgreements } from 'utils/agreements'
import { getPersonalData, setAuthData } from 'utils/auth'
import { sendNewUserToGTR } from 'utils/getResponse'
import { redirect } from 'utils/redirect'

const channelId = CHANNEL_IDS.OAS

type TsuccessLogin = (userPublicData: AxiosResponse<TUser>, push: History<LocationState>['push']) => Promise<void>

export const successLogin: TsuccessLogin = async (userPublicData, push) => {
  await setAuthData(userPublicData)
  const loadedAgreementDocuments = await loadAgreementDocuments(DEFAULT_AGREEMENT_TYPES)

  const { agreementTypesToUpdate, agreementDocuments } = await prepareAgreementTypesToUpdateForUser(
    DEFAULT_AGREEMENT_TYPES,
    loadedAgreementDocuments
  )

  // ============
  // remove after decision yt.dasreda.ru/issue/BPD-5825#focus=Comments-4-58011.0-0
  try {
    const { id: userId, publicName } = userPublicData.data
    const personalData = await getPersonalData(userId)
    const { email } = personalData.data

    await sendNewUserToGTR(
      { email, publicName, agreements: DEFAULT_AGREEMENT_TYPES, channelId },
      { isFastRegChannel: true }
    )
  } catch (error) {
    console.error("For some reason user wasn't added to GTR", error)
  }
  // ============

  if (agreementTypesToUpdate.length > 0) {
    if (process.env.REACT_APP_BUILD === 'coop') {
      redirect({ push, url: '/agreements' }, { agreementTypesToUpdate, agreementDocuments })
    } else {
      await updateAgreements({ agreementTypesToUpdate, agreementDocuments })

      redirect()
    }
  } else {
    redirect()
  }
}
