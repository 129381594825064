export const USER_AGREEMENT = 'user_agreement'
export const PUBLISH_PD = 'publish_pd'
export const COMPANY_POLICY = 'company_policy'
export const DUMMY_AGREEMENT = 'dummy_agreement'
export const TERMS_OF_USE_DS_DISPATCH = 'terms_of_use_ds_dispatch'
export const VOLUNTARY_ACTION_MSP_DISPATCH = 'voluntary_action_msp_dispatch'
export const SUBSCRIPTION_DISPATCH = 'subscription_dispatch'
export const MSP_DISPATCH = 'mspAgreements_dispatch'
export const SENDINGS_AGREEMENT = 'sendings_agreement'

const AGREEMENTS = {
  [USER_AGREEMENT]: USER_AGREEMENT,
  [PUBLISH_PD]: PUBLISH_PD,
  [COMPANY_POLICY]: COMPANY_POLICY,
  [DUMMY_AGREEMENT]: DUMMY_AGREEMENT,
  [TERMS_OF_USE_DS_DISPATCH]: TERMS_OF_USE_DS_DISPATCH,
  [VOLUNTARY_ACTION_MSP_DISPATCH]: VOLUNTARY_ACTION_MSP_DISPATCH,
  [SUBSCRIPTION_DISPATCH]: SUBSCRIPTION_DISPATCH,
  [MSP_DISPATCH]: MSP_DISPATCH,
  [SENDINGS_AGREEMENT]: SENDINGS_AGREEMENT,
}

export default AGREEMENTS
