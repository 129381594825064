import { FC } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { ISbbolResult } from '../types'

import ResultPage from 'containers/OAuth/components/ResultPage'
import onLoadSbbol from 'containers/OAuth/utils/onLoadSbbol'

const SbbolResult: FC<ISbbolResult & RouteComponentProps> = ({
  location: {
    query: { code },
  },
  history: { push },
}) => <ResultPage onLoad={onLoadSbbol({ code, push })} />

export default SbbolResult
