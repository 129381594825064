import { useEffect, useState } from 'react'

import { UseSecondsTimerType } from './types'

const TIMER_MAX_SECONDS = 59

export const useSecondsTimer: UseSecondsTimerType = (initialSeconds = TIMER_MAX_SECONDS) => {
  const [seconds, setSeconds] = useState<number>(initialSeconds)
  const resetTimer = () => setSeconds(initialSeconds)
  const timeIsOver = seconds <= 0

  useEffect(() => {
    const interval = setInterval(() => seconds > 0 && setSeconds(seconds - 1), 1000)

    return () => clearInterval(interval)
  }, [seconds])

  return [seconds, timeIsOver, resetTimer, setSeconds]
}
