import { FC, useCallback, useEffect, useState } from 'react'
import { useGrid } from '@dasreda/components'
import { SberId as SberIdIcon } from '@dasreda/icons'

import { request } from 'utils/api'

import { SSbbolIdButton } from './styled.index'

const SberBusinessId: FC = () => {
  const [, setStatus] = useState<boolean>(false)
  const [url, setUrl] = useState<string>('#loading')
  const media = useGrid()

  const generateLink = useCallback(async () => {
    try {
      const res = await request({
        location: `single-auth:sbbol/generate-url`,
        method: 'get',
        params: {
          params: {
            db_schema: 'public',
          },
        },
      })

      const { data: sbbolOriginUrl } = res
      setUrl(sbbolOriginUrl)
      setStatus(true)
    } catch (error) {
      console.error(error)
    }
  }, [setUrl, setStatus])

  useEffect(() => {
    generateLink()
  }, [generateLink])

  return (
    <SSbbolIdButton
      to={url}
      type="button"
      color="textGraphite"
      size={media.xlOrMore ? 'large' : 'medium'}
      icon={<SberIdIcon />}
      iconPosition="left"
    >
      Войти по СберБизнес ID
    </SSbbolIdButton>
  )
}

export default SberBusinessId
