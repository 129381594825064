import { FC } from 'react'

import SubmitButton from 'containers/FormBuilder/components/SubmitButton'

// TODO: fix import from configs/form (=containers), move hook to utils!
import { useSecondsTimer } from '../../hooks/useSecondsTimer'

interface IKmRegistrationButtons {
  onSubmitMessage: () => void
  onSubmitInternal: () => void
  submitting: boolean
  hasValidationErrors: boolean
}

const KmRegistrationButtons: FC<IKmRegistrationButtons> = ({
  onSubmitMessage,
  onSubmitInternal,
  submitting,
  hasValidationErrors,
}) => {
  const [messageSendingSeconds, messageSendingTimerIsOver, resetSendingTimer] = useSecondsTimer(0)
  const handleSubmitMessage = () => {
    onSubmitMessage()
    resetSendingTimer()
  }

  return (
    <>
      <SubmitButton
        type="submit"
        onClick={handleSubmitMessage}
        disabled={submitting || hasValidationErrors || !messageSendingTimerIsOver}
      >
        {messageSendingTimerIsOver ? 'Отправить сообщение' : `0:${String(messageSendingSeconds).padStart(2, '0')}`}
      </SubmitButton>
      <p>Отправить пользователю сообщение с ссылкой на регистрацию</p>
      <SubmitButton type="submit" onClick={onSubmitInternal} disabled={submitting || hasValidationErrors}>
        Оффлайн регистрация
      </SubmitButton>
      <p>Открыть форму регистрации на этом устройстве</p>
    </>
  )
}

export default KmRegistrationButtons
