import { Title } from '@dasreda/components'
import styled, { css } from 'styled-components'

export const SEmailConfirmationWrapper = styled.div(
  () =>
    css`
      flex-direction: row;
    `
)

export const SEmailConfirmationLogo = styled.div(
  () =>
    css`
      margin: 40px auto 0 auto;
      text-align: center;
    `
)

export const SEmailConfirmationTitle = styled(Title)(
  () =>
    css`
      margin: 20px auto;
      text-align: center;
    `
)

export const SEmailConfirmationSubTitle = styled(Title)(
  () =>
    css`
      margin: 0 auto 40px auto;
      text-align: center;
    `
)
