import { ComponentType } from 'react'
import { RouteComponentProps } from 'react-router-dom'

/* eslint-disable import/extensions, import/no-unresolved */
// @ts-expect-error: APP_BUILD depends on REACT_APP_BUILD from `yarn start` command
import forms from './APP_BUILD/index.ts'
import common from './app'

type TRoutes = {
  path: string
  exact: boolean
  component?: ComponentType<RouteComponentProps>
  configName?: string
}

const routes: TRoutes[] = [...common, ...forms]

export default routes
