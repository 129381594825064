import { FC, KeyboardEvent, MouseEvent, useState } from 'react'
import { Close } from '@dasreda/icons'

import { IAlert } from './types'

import { SAlertClose, SAlertText, SAlertWrapper } from './styled.index'

const Alert: FC<IAlert> = ({ text, type }) => {
  const [close, setClose] = useState<boolean>(false)

  const handleClose = (e: MouseEvent | KeyboardEvent) => {
    e.preventDefault()
    setClose(true)
  }

  return !close ? (
    <SAlertWrapper $type={type}>
      <SAlertText>{text}</SAlertText>
      <SAlertClose onClick={handleClose} onKeyDown={handleClose}>
        <Close />
      </SAlertClose>
    </SAlertWrapper>
  ) : null
}

export default Alert
