import { FormApi } from 'final-form'
import { sendKmSubscriptionShortUrlVariables as ISendKmSubscriptionShortUrlVariables } from 'gql/mutations/generated/sendKmSubscriptionShortUrl'
import { History, LocationState } from 'history'
import qs from 'qs'

import { configs } from 'eco-api'

import { TFormConfig } from 'containers/FormBuilder'
import { redirect } from 'utils/redirect'

import { defaultParams } from './constants'

const { resolvePortalUrl } = configs

export const getRedirectLink = (params: unknown) => `${resolvePortalUrl()}cart?${qs.stringify(params)}`

export const postRender: TFormConfig['postRender'] = ({
  location: { state, pathname, search },
  history: { replace, push },
}) => {
  const isRedirect = (state as { isRedirect?: boolean })?.isRedirect
  if (isRedirect) {
    replace({
      pathname,
      search,
      state: {},
    })
  } else {
    redirect({
      push,
      url: '/registration-secondary/subscription',
    })
  }
}

export const getParams = () => {
  const [, query] = window.location.search.split('?')
  return qs.parse(query)
}

export const internalRegRedirect = ({ push }: { push: History<LocationState>['push'] }) => {
  const { number, email } = getParams()
  const params = {
    ...defaultParams,
    number,
    email,
  }
  const url = getRedirectLink(params)

  return redirect({ push, url })
}

export const getSubmitFn =
  (form: FormApi, sendKmSubscriptionShortUrl: (params: ISendKmSubscriptionShortUrlVariables) => void) => async () => {
    await form.submit()

    const { submitSucceeded } = form.getState()

    if (submitSucceeded) {
      const params = getParams()
      const { number, email, phone } = params
      return sendKmSubscriptionShortUrl({
        number,
        email,
        phone,
      } as {
        number: string
        email: string
        phone: string
      })
    }

    return false
  }
