import { Link, media } from '@dasreda/components'
import styled, { css } from 'styled-components'

import { SWrapperProps } from './types'

export const SWrapper = styled.div<SWrapperProps>(
  ({ withoutMargin }) => css`
    width: 400px;
    margin-top: ${withoutMargin ? '0' : '24px'};

    ${media.xs} {
      width: 100%;
    }
  `
)

export const SSeparator = styled.div(
  ({ theme }) => css`
    position: relative;
    margin-bottom: 24px;
    text-align: center;

    &:before {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      margin: auto;
      background-color: ${theme.palette.dividerLight};
      content: '';
    }

    span {
      position: relative;
      padding: 0 24px;
      color: ${theme.palette.textGray};
      font: ${theme.font.tabletBig.p1};
      background-color: ${theme.palette.white};

      ${media.xl} {
        font: ${theme.font.desktop.p1};
      }
    }
  `
)

export const SSocialButtons = styled.div`
  margin-top: 17px;

  button {
    margin: 0 17px;
    padding: 0;
    background-color: transparent;
  }
`

export const SGosuslugiButton = styled(Link)(
  ({ theme }) => css`
    width: 100%;
    margin-bottom: 16px;

    &:hover {
      > span {
        color: ${theme.palette.textGraphite} !important;
      }
    }

    > span {
      color: ${theme.palette.textGraphite};
      font: ${theme.fontSystem.middleSemiBold};
      box-shadow: inset 0 0 0 1px ${theme.palette.border};

      ${media.xl} {
        font: ${theme.fontSystem.largeSemiBold};
      }

      svg {
        position: relative;
        top: -1px;
        height: 25px;
        margin-left: 6px;

        ${media.xl} {
          height: 28px;
        }
      }
    }
  `
)

export const SDemoAccessButton = styled(Link)(
  ({ theme }) => css`
    width: 100%;
    margin-bottom: 16px;

    > span {
      color: ${theme.palette.textGraphite};
      font: ${theme.fontSystem.middleSemiBold};
      box-shadow: inset 0 0 0 1px ${theme.palette.border};

      ${media.xl} {
        font: ${theme.fontSystem.largeSemiBold};
      }
    }
  `
)

export const SSberIdButton = styled(Link)(
  ({ theme }) => css`
    width: 100%;
    margin-bottom: 16px;

    > span {
      color: ${process.env.REACT_APP_BUILD === 'coop' ? theme.palette.textGraphite : theme.palette.emerald};
      font: ${theme.fontSystem.middleSemiBold};
      box-shadow: inset 0 0 0 1px ${theme.palette.border};

      ${media.xl} {
        font: ${theme.fontSystem.largeSemiBold};
      }

      svg {
        fill: ${process.env.REACT_APP_BUILD === 'coop' ? theme.palette.sberId : theme.palette.emerald};
      }
    }

    ${process.env.REACT_APP_BUILD === 'coop' &&
    css`
      &:hover {
        > span {
          background-color: ${theme.palette.emeraldHover};
          box-shadow: inset 0 0 0 1px ${theme.palette.emeraldHover};

          * {
            color: ${theme.palette.white} !important;
          }

          svg {
            fill: ${theme.palette.white};
          }
        }
      }
    `}

    ${process.env.REACT_APP_BUILD === 'coop' &&
    css`
      &:active {
        > span {
          background-color: ${theme.palette.emeraldDark};
          box-shadow: inset 0 0 0 1px ${theme.palette.emeraldDark};

          * {
            color: ${theme.palette.white} !important;
          }

          svg {
            fill: ${theme.palette.white};
          }
        }
      }
    `}
  `
)

export const SSbbolIdButton = styled(Link)(
  ({ theme }) => css`
    width: 100%;
    margin-bottom: 16px;

    ${process.env.REACT_APP_BUILD === 'coop' &&
    css`
      > span {
        font: ${theme.fontSystem.middleSemiBold};
        background-color: ${theme.palette.emerald};
        box-shadow: inset 0 0 0 1px ${theme.palette.emerald};

        ${media.xl} {
          font: ${theme.fontSystem.largeSemiBold};
        }

        * {
          color: ${theme.palette.white} !important;
        }

        svg {
          fill: ${theme.palette.white};
        }
      }

      &:hover {
        > span {
          background-color: ${theme.palette.emeraldHover};
          box-shadow: inset 0 0 0 1px ${theme.palette.emeraldHover};
        }
      }

      &:active {
        > span {
          background-color: ${theme.palette.emeraldDark};
          box-shadow: inset 0 0 0 1px ${theme.palette.emeraldDark};
        }
      }
    `}

    ${process.env.REACT_APP_BUILD !== 'coop' &&
    css`
      > span {
        color: ${theme.palette.coopGreen};
        font: ${theme.fontSystem.middleSemiBold};
        box-shadow: inset 0 0 0 1px ${theme.palette.border};

        ${media.xl} {
          font: ${theme.fontSystem.largeSemiBold};
        }

        svg {
          fill: ${theme.palette.coopGreen};
        }
      }
    `}
  `
)
