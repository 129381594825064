import { FC } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { AxiosError, AxiosResponse } from 'axios'
import { History, LocationState } from 'history'
import get from 'lodash/get'
import qs from 'qs'
import { TUser } from 'types/user'

import DEFAULT_AGREEMENT_TYPES from 'configs/agreementTypes'

import { request } from 'utils/api'

import ResultPage, { TSetMessage } from '../../../components/ResultPage'
import SberIdBtn from '../../Buttons/SberId'
import { successLogin } from '../utils'

// Документация
// https://yt.dasreda.ru/_persistent/%D0%A1%D0%B1%D0%B5%D1%80%D0%B1%D0%B0%D0%BD%D0%BA%20ID.%20%D0%9F%D0%B0%D1%80%D0%B0%D0%BC%D0%B5%D1%82%D1%80%D1%8B%20%D0%B7%D0%B0%D0%BF%D1%80%D0%BE%D1%81%D0%B0%20%D0%B2%20web%20v2.pdf?file=5-8925&c=false&updated=1574773090930

// Возможные типы ошибок от Сбербанка, сейчас все типы обрабатываю одинаково
// https://yt.dasreda.ru/issue/BPD-3938#focus=streamItem-4-34681.0-0
// https://www.sberbank.ru/common/img/uploaded/files/sberbank_id/connect_sberbankid_v.1.5.pdf

const onLoad =
  ({ code, push }: TSberIdParams) =>
  (setError: TSetMessage) => {
    if (!code) {
      setError('Отсутствует обязательный параметр — code')
      return
    }

    // На бекенде происходит регистрация пользователя по каналу OAS
    request({
      location: `single-auth:sbol/user/authorize/${code}?${qs.stringify({ typesAgreement: DEFAULT_AGREEMENT_TYPES.join(',') })}`,
      method: 'get',
    })
      .then((resAuthByCode: AxiosResponse<TUser>) => {
        const token = get(resAuthByCode, 'data.token')
        if (token) successLogin(resAuthByCode, push)
      })
      .catch((err: AxiosError) => {
        const { error } = err.response?.data

        if (error) setError(`${error.message}. ${error.description}`)
        else setError('Авторизация пользователя завершилась с ошибкой')
      })
  }

type TSberIdParams = {
  code: string
  push: History<LocationState>['push']
}

// TODO: use RouteComponentProps, but first should remove custom query prop from history.js
interface ISberId {
  location: {
    query: TSberIdParams
  }
}

const SberId: FC<ISberId & RouteComponentProps> = ({
  location: {
    query: { code },
  },
  history: { push },
}) => <ResultPage onLoad={onLoad({ code, push })} loginBtn={<SberIdBtn withDeepLink={false} />} />

export default SberId
