import { useMutation } from '@apollo/client'
import { loader } from 'graphql.macro'

import { sendSmsToTemplateSlug, sendSmsToTemplateSlugVariables } from './types'

const SEND_SMS_TO_TEMPLATE_SLUG = loader('src/gql/mutations/sendSmsToTemplateSlug.gql')

const useSendSmsToTemplateSlug = (templateSlug: string) => {
  const [sendEmail] = useMutation<sendSmsToTemplateSlug, sendSmsToTemplateSlugVariables>(SEND_SMS_TO_TEMPLATE_SLUG)

  return (phone: string) => {
    sendEmail({
      variables: { phone, templateSlug },
    })
  }
}

export default useSendSmsToTemplateSlug
