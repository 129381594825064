import { FC } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import { config, IHeader } from './types'

import { SHeaderLink, SHeaderText, SHeaderWrapper } from './styled.index'

const Header: FC<IHeader & RouteComponentProps> = ({ type, location }) => {
  const { search } = location
  const dataForm = config[type]

  return (
    <SHeaderWrapper onlyText={dataForm.onlyText}>
      {dataForm.description && <SHeaderText>{dataForm.description}</SHeaderText>}
      {dataForm.link && dataForm.title && (
        <SHeaderLink to={{ pathname: dataForm.link, search }}>{dataForm.title}</SHeaderLink>
      )}
    </SHeaderWrapper>
  )
}

export default withRouter(Header)
