import { useCallback, useMemo } from 'react'
import { useLazyQuery } from '@apollo/client'
import { listRegions, listRegionsVariables } from 'gql/queries/generated/listRegions'
import { loader } from 'graphql.macro'

const listRegionsGql = loader('src/gql/queries/listRegions.gql')

const useBase = () => {
  const lazyQuery = useLazyQuery<listRegions, listRegionsVariables>(listRegionsGql, {
    variables: {
      city: '',
    },
    fetchPolicy: 'cache-first',
  })

  const [getRegions, { data: regionsData }] = lazyQuery

  const cities = useMemo(() => {
    if (regionsData?.listRegions?.__typename !== 'RegionsList' || !regionsData?.listRegions.regions) {
      return []
    }

    const { regions } = regionsData.listRegions

    return regions.map((region) => ({
      text: region ? `${region.city}, ${region.region}` : '',
      value: region ? `${region.city}, ${region.region}` : '',
    }))
  }, [regionsData])

  const getCities = useCallback(
    (city: string) => {
      getRegions({
        variables: {
          city,
        },
      })
    },
    [getRegions]
  )

  return {
    cities,
    getCities,
  }
}

export default useBase
