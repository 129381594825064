import { FC } from 'react'
import { useGrid } from '@dasreda/components'

import { IButtonProps } from '@dasreda/components/build/lib/components/Button/types'

import { SSubmitButton } from '../../styled.index'

const SubmitButton: FC<IButtonProps> = ({ children, ...props }) => {
  const media = useGrid()

  return (
    <SSubmitButton size={media.xlOrMore ? 'large' : 'medium'} {...props}>
      {children}
    </SSubmitButton>
  )
}

export default SubmitButton
