import { TRequest } from 'types/api'

import { IFormConfigFnPropsWithClient } from './types'

import { Footer, Header } from 'components'
import { TFormConfigFn } from 'containers/FormBuilder'
import { request } from 'utils/api'
import { email, required } from 'utils/validators'

import { emailFieldProps } from '../../defaultFieldsProps'

const passwordResetRequest: TFormConfigFn<IFormConfigFnPropsWithClient> = ({
  location: {
    query: { system },
  },
}) => ({
  title: 'Забыли пароль?',
  header: <Header type="password-reset-email" />,
  submitButtonText: 'Восстановить пароль',
  fields: [
    {
      ...emailFieldProps,
      name: 'email',
      qa: 'password_request_field',
      validators: [required, email],
    },
  ],
  successMessage: 'Инструкции для смены пароля отправлены вам на почту',
  onRequest: (values) => {
    const config: TRequest = {
      location: 'core-router/v2:user/restore/email',
      method: 'post',
      params: {
        data: { ...values, system },
      },
    }

    return request(config)
  },
  footer: <Footer type="resetPassword" align="center" />,
})

export default passwordResetRequest
