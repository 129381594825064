import { dummyUser } from 'configs/consts'

export const normalizePhone = (value) => {
  if (!value) return value
  let number = value.replace(/\D/g, '')
  number = number.replace(/^8/, '7')
  if (!number.match(/^7/)) number = `7${number}`
  return number
}

export const normalizeDummyName = (value) => {
  if (value === `${dummyUser.firstName}  ${dummyUser.lastName}.`) return ''
  return value
}
