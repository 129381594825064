import styled, { css } from 'styled-components'

import { ISAlertWrapperProps, TTextStatus } from './types'

const getColors = (type: TTextStatus) => {
  switch (type) {
    case 'danger':
      if (process.env.REACT_APP_BUILD === 'coop') {
        return {
          font: 'rubyText',
          bg: 'rubyBg',
          border: 'rubyText',
        }
      }

      return {
        font: 'pink',
        bg: 'pinkBg',
        border: 'pinkLight',
      }
    case 'info':
      return {
        font: 'blue',
        bg: 'blue100',
        border: 'blueLight',
      }
    case 'warning':
      return {
        font: 'orange',
        bg: 'orange100',
        border: 'brand500',
      }
    case 'success':
    default:
      if (process.env.REACT_APP_BUILD === 'coop') {
        return {
          font: 'skyText',
          bg: 'skyBg',
          border: 'skyText',
        }
      }

      return {
        font: 'emerald',
        bg: 'emeraldBg',
        border: 'emeraldLight',
      }
  }
}

export const SAlertText = styled.div(
  ({ theme }) => css`
    font: ${theme.font.desktop.p3};
    text-align: left;
  `
)

export const SAlertClose = styled.div(
  () => css`
    position: absolute;
    top: 0;
    right: 14px;
    bottom: 0;
    width: 24px;
    height: 24px;
    margin: auto 0;

    svg {
      width: 100%;
      height: 100%;
    }
  `
)

export const SAlertWrapper = styled.div<ISAlertWrapperProps>(
  ({ theme, $type }) => css`
    position: relative;
    width: 100%;
    margin-top: ${process.env.REACT_APP_BUILD === 'coop' ? '24px' : '0'};
    margin-bottom: 32px;
    padding: 16px 56px 16px 16px;
    background-color: ${theme.palette[getColors($type).bg]};
    border: 1px solid ${theme.palette[getColors($type).border]};
    border-radius: ${theme.radius.r12};

    ${SAlertText} {
      color: ${theme.palette[getColors($type).font]};
    }

    ${SAlertClose} {
      svg {
        fill: ${theme.palette[getColors($type).border]};
      }
    }
  `
)
