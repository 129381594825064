import { isEmpty } from 'lodash'

export const parseQueryString = (queryString) => {
  if (isEmpty(queryString)) return {}
  const queries = decodeURIComponent(queryString).split('&')

  const keyForParse = 'redirect'
  return queries.reduce((params, query) => {
    const nextParams = { ...params }
    const [key, value] = query.split('=')
    nextParams[key] = key === keyForParse ? query.replace(`${keyForParse}=`, '') : value
    return nextParams
  }, {})
}
