import { Timer } from '@dasreda/components'

import { TFormConfig } from 'containers/FormBuilder'
import SubmitButton from 'containers/FormBuilder/components/SubmitButton'
import { email as emailValidator, required } from 'utils/validators'

import useBase from './defaultLogin/components/SubmitBlock/useBase'
import useSendEmailToTemplateSlug from 'hooks/useSendEmailToTemplateSlug'

import { emailFieldProps } from '../../defaultFieldsProps'
import { SLogo } from '../styled.index'

const SubmitBlock: TFormConfig['submitBlock'] = (props) => {
  const { submitting, hasValidationErrors, form } = props
  const { bruteforceProtectIsActive, bruteforceSecondsLeft } = useBase(props)

  const sendEmailToTemplateSlug = useSendEmailToTemplateSlug(
    'Завершение приобретения подписки Премиум',
    'premium-sbbol'
  )

  const sendEmail = async () => {
    await form.submit()

    const { submitSucceeded, values } = form.getState()

    if (submitSucceeded) {
      const { email } = values

      return sendEmailToTemplateSlug(email as string)
    }

    return false
  }

  const handleOnClick = async () => {
    await sendEmail()
  }

  return (
    <SubmitButton
      type="submit"
      disabled={submitting || hasValidationErrors || bruteforceProtectIsActive}
      onClick={handleOnClick}
    >
      {!bruteforceProtectIsActive && 'Отправить письмо'}
      {bruteforceProtectIsActive && bruteforceSecondsLeft > 0 && <Timer seconds={bruteforceSecondsLeft} />}
    </SubmitButton>
  )
}

const sbbolPremiumKm: TFormConfig = {
  title: '',
  header: <SLogo />,
  submitBlock: SubmitBlock,
  fields: [
    {
      ...emailFieldProps,
      validators: [required, emailValidator],
      name: 'email',
      placeholder: 'E-mail',
    },
  ],
  successMessage: 'На указанный вами e-mail отправлено письмо со ссылкой для подтверждения регистрации',
  onRequest: () => {},
  postHook: () => {},
}

export default sbbolPremiumKm
