import { FC } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { ISbbolResult } from '../types'

import { configs } from 'eco-api'

import { SBBOL_PREMIUM } from 'configs/consts'
import ResultPage from 'containers/OAuth/components/ResultPage'
import onLoadSbbol from 'containers/OAuth/utils/onLoadSbbol'

const { resolvePortalUrl } = configs

const REDIRECT_URL = `${resolvePortalUrl()}premium-sbbol`

const SbbolPremiumResult: FC<ISbbolResult & RouteComponentProps> = ({
  location: {
    query: { code },
  },
  history: { push },
}) => <ResultPage onLoad={onLoadSbbol({ code, push, product: SBBOL_PREMIUM, redirectUrl: REDIRECT_URL })} />

export default SbbolPremiumResult
