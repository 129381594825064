import { Timer } from '@dasreda/components'
import getApolloClient from 'gql/helpers/getApolloClient'
import { loader } from 'graphql.macro'

import { Header } from 'components'
import { TFormConfig } from 'containers/FormBuilder'
import SubmitButton from 'containers/FormBuilder/components/SubmitButton'
import { SCheckboxInput } from 'containers/FormBuilder/styled.index'
import {
  emailNotRequired as emailNotRequiredValidator,
  phoneNotRequired as phoneNotRequiredValidator,
  required,
} from 'utils/validators'

import useBase from './defaultLogin/components/SubmitBlock/useBase'
import useSendEmailToTemplateSlug from 'hooks/useSendEmailToTemplateSlug'
import useSendSmsToTemplateSlug from 'hooks/useSendSmsToTemplateSlug'

import { TERMS_OF_USE_DS_DISPATCH } from '../../agreements'
import { emailFieldProps, phoneFieldProps } from '../../defaultFieldsProps'

const saveClientFieldsMutation = loader('src/gql/mutations/saveClientFields.gql')

const SubmitBlock: TFormConfig['submitBlock'] = (props) => {
  const { submitting, hasValidationErrors, form } = props
  const { bruteforceProtectIsActive, bruteforceSecondsLeft } = useBase(props)

  const sendEmailToTemplateSlug = useSendEmailToTemplateSlug(
    '🧠 Информация о курсе «Адаптация бизнеса к новым реалиям»',
    'adaptacziya-biznesa'
  )

  const sendSmsToTemplateSlug = useSendSmsToTemplateSlug('adaptacziya-biznesa-sms')

  const sendToYandexCloud = async (email: string, phone: string) => {
    const apolloClient = await getApolloClient

    return apolloClient.mutate({
      mutation: saveClientFieldsMutation,
      variables: {
        email,
        phone,
      },
      fetchPolicy: 'network-only',
    })
  }

  const sendEmailSms = async () => {
    await form.submit()
    const { submitSucceeded, values } = form.getState()

    if (submitSucceeded) {
      const { email, phone } = values

      sendToYandexCloud(email, phone)

      return {
        email: email && sendEmailToTemplateSlug(email as string),
        phone: phone && sendSmsToTemplateSlug(`+${phone}`),
      }
    }

    return false
  }

  const handleOnClick = async () => {
    await sendEmailSms()
  }

  return (
    <SubmitButton
      type="submit"
      disabled={submitting || hasValidationErrors || bruteforceProtectIsActive}
      onClick={handleOnClick}
    >
      {!bruteforceProtectIsActive && 'Отправить ссылку'}
      {bruteforceProtectIsActive && bruteforceSecondsLeft > 0 && <Timer seconds={bruteforceSecondsLeft} />}
    </SubmitButton>
  )
}

const bizAdaptation: TFormConfig = {
  title: 'Адаптация бизнеса к новым реалиям',
  header: <Header type="biz-adaptation" />,
  submitBlock: SubmitBlock,
  fields: [
    {
      ...emailFieldProps,
      validators: [emailNotRequiredValidator],
      name: 'email',
      placeholder: 'Адрес почты',
    },
    {
      ...phoneFieldProps,
      validators: [phoneNotRequiredValidator],
    },
    {
      name: TERMS_OF_USE_DS_DISPATCH,
      component: SCheckboxInput,
      label: (
        <span>
          Я подтверждаю, что вышеприведенные сведения заполнены со слов представителя субъекта малого среднего
          предпринимательства, и он уведомлен о передаче его персональных данных в адрес АО «Деловая среда»
        </span>
      ),
      qa: 'dispatchDsAgree_checkbox',
      validators: [required],
    },
  ],
  successMessage: 'На указанный вами e-mail и телефон отправлены письма со ссылкой',
  onRequest: () => {},
  postHook: () => {},
}

export default bizAdaptation
