import cloneDeep from 'lodash/cloneDeep'
import { TResUser } from 'types/user'

import DEFAULT_AGREEMENT_TYPES from 'configs/agreementTypes'

import { Footer, Header } from 'components'
import CHANNEL_IDS from 'configs/channels'
import { DATALAYER_CUSTOM_EVENT, DATALAYER_EVENT_REGISTRATION } from 'configs/dataLayer'
import {
  emailFieldProps,
  nameFieldProps,
  passwordFieldProps,
  passwordRepeatFieldProps,
  surnameFieldProps,
} from 'configs/defaultFieldsProps'
import { TFormConfig, TFormConfigFn } from 'containers/FormBuilder'
import { TValues } from 'containers/FormBuilder/components/Field'
import { request } from 'utils/api'
import { setAuthData } from 'utils/auth'
import getFormFieldsByType from 'utils/getFormFieldsByType'
import { redirect, redirectPreRenderHook } from 'utils/redirect'
import { commonRegistrationActions } from 'utils/registration'
import { dataLayerHandler } from 'utils/sendDataLayer'
import { email, equal, passwordStrongValidator, required } from 'utils/validators'

const channelId = CHANNEL_IDS.SLF

const config = {
  course_access: {
    title: 'Уроки курса доступны только зарегистрированным пользователям',
  },
}

type TPartialFormConfig = Partial<Pick<TFormConfig, 'title'>>
interface IReturnConfig extends TPartialFormConfig {
  initialValues?: TValues
}

const defaultRegistration: TFormConfigFn<{ client_id: string; type: string }> = ({
  location: {
    query: { client_id, type },
  },
}) => {
  const defaultTitle = client_id
    ? `Войдите или зарегистрируйтесь для авторизации на &quot;${client_id}&quot;`
    : 'Регистрация'

  const { title = defaultTitle, initialValues: extraInitialValues } = getFormFieldsByType<IReturnConfig>(config)(type)

  return {
    title,
    submitButtonText: 'Зарегистрироваться',
    header: <Header type="registration" />,
    footer: <Footer type="dsAgree" />,
    initialValues: { ...extraInitialValues },
    fields: [
      {
        ...nameFieldProps,
        validators: [required, ...(nameFieldProps.validators as [])],
      },
      {
        ...surnameFieldProps,
        validators: [required, ...(surnameFieldProps.validators as [])],
      },
      {
        ...emailFieldProps,
        validators: [required, email],
      },
      {
        ...passwordFieldProps,
        validators: [required, passwordStrongValidator],
      },
      {
        ...passwordRepeatFieldProps,
        validators: [required, passwordStrongValidator, (value, allValues) => equal(value, allValues, 'password')],
      },
    ],
    successMessage: 'На указанный вами e-mail отправлено письмо со ссылкой для подтверждения регистрации',
    postRender: redirectPreRenderHook,
    agreementTypesToLoad: DEFAULT_AGREEMENT_TYPES,
    defaultTruthForAgreementTypes: DEFAULT_AGREEMENT_TYPES,
    onRequest: (values) => {
      const nextData = cloneDeep(values)
      delete nextData.passwordMatch

      const reqParams = {
        location: `core-router/v2:register/${channelId}`,
        params: {
          withCredentials: true,
          data: {
            ...nextData,
            isFirstLogin: true,
            channelId,
          },
        },
      }

      return request(reqParams)
    },
    postHook: (_, response, { history: { push } }) => {
      if (response) {
        setAuthData(response as TResUser)
        commonRegistrationActions()

        const { data } = response as TResUser

        dataLayerHandler({
          event: DATALAYER_CUSTOM_EVENT,
          eventCategory: DATALAYER_EVENT_REGISTRATION,
          eventAction: channelId,
          userId: data?.id,
        })

        redirect({ push, url: '/confirmation' }, { userEmail: data.maskedEmail })
      }
    },
  }
}

export default defaultRegistration
