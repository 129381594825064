import { FC, useCallback, useEffect } from 'react'

import { request } from 'utils/api'

interface ISbolRedirect {
  location: {
    query: {
      product: string
    }
  }
}

interface ISbbolParams {
  db_schema?: string
  clientId?: string
}

const SbolRedirect: FC<ISbolRedirect> = ({
  location: {
    query: { product },
  },
}) => {
  const getParams = useCallback(async () => {
    try {
      const params: ISbbolParams = { db_schema: 'public' }

      const res = await request({
        location: 'single-auth:sbol/generate-url',
        method: 'get',
        params: { params },
      })

      window.location = res.data
    } catch (error) {
      console.info(error)
    }
  }, [product])

  useEffect(() => {
    getParams()
  }, [getParams])

  return null
}

export default SbolRedirect
