import { NavLink } from 'react-router-dom'
import { Eye, EyeBlock } from '@dasreda/icons'
import styled, { css } from 'styled-components'

export const SFieldWrapper = styled.div`
  position: relative;
`

export const SFieldWrapperComponent = styled.div`
  position: absolute;
  top: 16px;
  right: 20px;
  max-height: 24px;
`

export const SFieldWrapperComponentLink = styled(NavLink)(
  ({ theme }) => css`
    font: ${theme.fontSystem.middle};
  `
)

export const SEyeIcon = styled(Eye)(
  ({ theme }) => css`
    width: 24px;
    height: 24px;
    fill: ${theme.palette.textGray};
  `
)

export const SEyeBlockIcon = styled(EyeBlock)(
  ({ theme }) => css`
    width: 24px;
    height: 24px;
    fill: ${theme.palette.textGray};
  `
)
