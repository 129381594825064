import KmRegistrationButtons from 'components/KmRegistrationButtons'
import { SErrorText } from 'configs/forms/platform/kmSubscription/styled.index'
import { TFormConfig } from 'containers/FormBuilder'

import { getSubmitFn, internalRegRedirect, postRender } from './helpers'
import { useShortUrlSender } from './hooks'

const SubmitBlock: TFormConfig['submitBlock'] = ({ hasValidationErrors, form, history: { push } }) => {
  const sendKmSubscriptionShortUrl = useShortUrlSender()

  const submit = getSubmitFn(form, sendKmSubscriptionShortUrl)

  const handleInternalSubmit = async () => {
    internalRegRedirect({ push })
  }

  return (
    <>
      <SErrorText>Пользователь уже зарегистрирован</SErrorText>
      <KmRegistrationButtons
        onSubmitInternal={handleInternalSubmit}
        onSubmitMessage={submit}
        submitting={false}
        hasValidationErrors={!!hasValidationErrors}
      />
    </>
  )
}

const kmSubscriptionExistingUser: TFormConfig = {
  title: 'Регистрация нового пользователя Деловая Среда «Премиум»',
  submitBlock: SubmitBlock,
  fields: [],
  postRender,
  onRequest: () => Promise.resolve(true),
}

export default kmSubscriptionExistingUser
