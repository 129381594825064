import {COMPANY_POLICY, PUBLISH_PD, USER_AGREEMENT} from './agreements'

/** Константа с типами соглашений платформы, для которых НЕ НУЖНО выполнять обновление
 * после успешной авторизации.
 * Пользователь может отключить PUBLISH_PD в ЛК и после авторизации оно не будет обновляться, т.е.
 * свойство agreed останется в значении false* */
export const EXCLUDED_AGREEMENT_TYPES_PLATFORM = {
  PUBLISH_PD
}

const AGREEMENT_TYPES_PLATFORM: [typeof USER_AGREEMENT, typeof PUBLISH_PD, typeof COMPANY_POLICY] = [
  USER_AGREEMENT,
  PUBLISH_PD,
  COMPANY_POLICY,
]
const AGREEMENT_TYPES_COOP: [typeof USER_AGREEMENT, typeof PUBLISH_PD] = [USER_AGREEMENT, PUBLISH_PD]

function getAgreementTypes() {
  switch (process.env.REACT_APP_BUILD) {
    case 'coop':
      return AGREEMENT_TYPES_COOP
    default:
      return AGREEMENT_TYPES_PLATFORM
  }
}

export default getAgreementTypes()
