import { FORM_ERROR } from 'final-form'
import _ from 'lodash'

export function transformError(errors) {
  return _.reduce(
    _.get(errors, 'response.data.errors', []),
    (result, error) => {
      const nextError = error
      delete nextError.code

      let key = _.keys(nextError).pop()
      const value = _.values(nextError).pop()
      if (key === '_error') key = FORM_ERROR

      const nextResult = result
      nextResult[key] = value
      return nextResult
    },
    {}
  )
}
