import { useMutation } from '@apollo/client'
import {
  sendKmSubscriptionShortUrl as ISendKmSubscriptionShortUrl,
  sendKmSubscriptionShortUrlVariables as ISendKmSubscriptionShortUrlVariables,
} from 'gql/mutations/generated/sendKmSubscriptionShortUrl'
import { loader } from 'graphql.macro'

const sendKmSubscriptionShortUrlMutation = loader('src/gql/mutations/sendKmSubscriptionShortUrl.gql')

type TUseShortUrlSender = () => (params: ISendKmSubscriptionShortUrlVariables) => void

export const useShortUrlSender: TUseShortUrlSender = () => {
  const [sendKmSubscriptionShortUrl] = useMutation<ISendKmSubscriptionShortUrl, ISendKmSubscriptionShortUrlVariables>(
    sendKmSubscriptionShortUrlMutation
  )

  return ({ phone, email, number }: ISendKmSubscriptionShortUrlVariables) =>
    sendKmSubscriptionShortUrl({
      variables: {
        phone,
        email,
        number,
      },
    })
}
