const HSF = 'HSF'
const SLF = 'SLF'
const EML = 'EML'
const PHN = 'PHN'
const SBR = 'SBR'
const MFC = 'MFC'
const KMS = 'KMS'
const MSP = 'MSP'
const OAS = 'OAS'
const LMS = 'LMS'

const CHANNEL_IDS = { HSF, SLF, EML, PHN, SBR, MFC, KMS, MSP, OAS, LMS }
export default CHANNEL_IDS
