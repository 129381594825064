import { Dispatch, FC, ReactElement, SetStateAction, useEffect, useState } from 'react'

import Error from '../Error'

type TMessage = string

export type TSetMessage = Dispatch<SetStateAction<TMessage>>

interface IResultPage {
  onLoad: (setMessage: TSetMessage) => void
  loginBtn?: ReactElement
}

const ResultPage: FC<IResultPage> = ({ onLoad, loginBtn }) => {
  const [message, setMessage] = useState<TMessage>('Загрузка...')
  const url = new URL(window.location.href)
  const hasRequestError = url.searchParams.get('error')

  useEffect(() => {
    if (!hasRequestError) {
      onLoad(setMessage)
    }
  }, [onLoad, hasRequestError])

  return hasRequestError ? <Error>{loginBtn}</Error> : <p>{message}</p>
}

export default ResultPage
