import { FC, MouseEventHandler } from 'react'

import { IFieldSuffixWithStateProps, ILink, TConfig } from './types'

import { SEyeBlockIcon, SEyeIcon, SFieldSuffixLink, SFieldSuffixWithState } from './styled.index'

const FieldSuffixLink: FC<ILink> = ({ pathname, text }) => <SFieldSuffixLink to={{ pathname }}>{text}</SFieldSuffixLink>

export const FieldSuffixWithState: FC<IFieldSuffixWithStateProps> = ({
  defaultComponent,
  activeComponent,
  suffixState,
  setSuffixState,
  timeout = 0,
}) => {
  const onClick: MouseEventHandler<HTMLDivElement> = () => {
    setSuffixState((prevState) => !prevState)
    if (timeout) {
      setTimeout(() => setSuffixState((prevState) => !prevState), timeout)
    }
  }

  return (
    <SFieldSuffixWithState onClick={onClick}>{suffixState ? activeComponent : defaultComponent}</SFieldSuffixWithState>
  )
}

export const suffixTypes: TConfig = {
  password: {
    withState: {
      default: {
        component: <SEyeBlockIcon />,
        prop: { propName: 'type', propValue: 'password' },
      },
      active: {
        component: <SEyeIcon />,
        prop: { propName: 'type', propValue: 'text' },
      },
      timeout: 7000,
    },
  },
  loginDefault: {
    component: <FieldSuffixLink pathname="/login-by-phone" text="Телефон" />,
  },
  loginByPhone: {
    component: <FieldSuffixLink pathname="/login" text="Почта" />,
  },
  regByEmail: {
    component: <FieldSuffixLink pathname="/registration/phone" text="Телефон" />,
  },
  regByPhone: {
    component: <FieldSuffixLink pathname="/registration/email" text="Почта" />,
  },
  resetByEmail: {
    component: <FieldSuffixLink pathname="/password-reset-request/phone" text="Телефон" />,
  },
  resetByPhone: {
    component: <FieldSuffixLink pathname="/password-reset-request/email" text="Почта" />,
  },
}

export default suffixTypes
