import { useCallback, useEffect, useRef, useState } from 'react'
import { Cookies } from 'react-cookie'
import { useTimer } from '@dasreda/hooks'
import { parseInt } from 'lodash'

import { ISubmitBlock } from './types'

import {
  FIRST_LEVEL_ATTEMPTS_COUNT,
  FIRST_LEVEL_DISABLE_TIME_IN_SECONDS,
  FIRST_LEVEL_TIME_RANGE_IN_SECONDS,
  SECOND_LEVEL_ATTEMPTS_COUNT,
  SECOND_LEVEL_DISABLE_TIME_IN_SECONDS,
  SECOND_LEVEL_TIME_RANGE_IN_SECONDS,
  STORE_ATTEMPTS_COUNT_KEY,
  STORE_LAST_LEVEL_FIRST_ATTEMPT_TIME_KEY,
  STORE_SECONDS_LEFT_KEY,
  THIRD_LEVEL_ATTEMPTS_COUNT,
  THIRD_LEVEL_DISABLE_TIME_IN_SECONDS,
  THIRD_LEVEL_TIME_RANGE_IN_SECONDS,
} from './constants'
import getCurrentTime from './getCurrentTime'

const useBase = ({ submitting, hasValidationErrors }: ISubmitBlock) => {
  const cookies = new Cookies()
  const errorsCount = useRef(window.parseInt(cookies.get(STORE_ATTEMPTS_COUNT_KEY)) || 0)
  const [bruteforceProtectIsActive, setBruteforceProtectIsActive] = useState(false)
  const [bruteforceSecondsLeft, setBruteforceSecondsLeft] = useState(
    window.parseInt(cookies.get(STORE_SECONDS_LEFT_KEY)) || 0
  )
  const [isLastLevelBruteforceProtect, setIsLastLevelBruteforceProtect] = useState(false)
  const [bruteforceTimerIsActive, setBruteforceTimerIsActive] = useState(false)
  const { startTimer } = useTimer({
    onTimeLeft: () => {
      const currentTime = getCurrentTime()
      setBruteforceProtectIsActive(false)
      setBruteforceTimerIsActive(false)
      // если таймер закончился в куках оставляем запас в 1 секунду
      cookies.set(STORE_SECONDS_LEFT_KEY, 1, {
        maxAge: THIRD_LEVEL_TIME_RANGE_IN_SECONDS,
      })
      if (!isLastLevelBruteforceProtect) {
        cookies.set(STORE_LAST_LEVEL_FIRST_ATTEMPT_TIME_KEY, currentTime, {
          maxAge: THIRD_LEVEL_TIME_RANGE_IN_SECONDS,
        })
      }
    },
    beforeSecondLeft: (secondsLeft) => {
      setBruteforceSecondsLeft(secondsLeft)
      cookies.set(STORE_SECONDS_LEFT_KEY, secondsLeft, {
        maxAge: THIRD_LEVEL_TIME_RANGE_IN_SECONDS,
      })
    },
  })
  const resetBruteforceProtectData = useCallback(() => {
    cookies.remove(STORE_SECONDS_LEFT_KEY)
    cookies.remove(STORE_ATTEMPTS_COUNT_KEY)
    setBruteforceSecondsLeft(0)
    setIsLastLevelBruteforceProtect(false)
    errorsCount.current = 0
  }, [])

  useEffect(() => {
    const firstAttemptTime = parseInt(cookies.get(STORE_LAST_LEVEL_FIRST_ATTEMPT_TIME_KEY))

    if (!firstAttemptTime) {
      resetBruteforceProtectData()
    }
  }, [])

  useEffect(() => {
    const lastLevelFirstAttemptTime = parseInt(cookies.get(STORE_LAST_LEVEL_FIRST_ATTEMPT_TIME_KEY)) || 0
    const currentTime = getCurrentTime()
    const timeFromLastLevelFirstAttempt = currentTime - lastLevelFirstAttemptTime

    if (!lastLevelFirstAttemptTime && errorsCount.current > 0) {
      resetBruteforceProtectData()
      errorsCount.current = 1
      cookies.set(STORE_LAST_LEVEL_FIRST_ATTEMPT_TIME_KEY, currentTime, {
        maxAge: THIRD_LEVEL_TIME_RANGE_IN_SECONDS,
      })
    }

    let disableSeconds = 0

    if (
      errorsCount.current === FIRST_LEVEL_ATTEMPTS_COUNT &&
      timeFromLastLevelFirstAttempt < FIRST_LEVEL_TIME_RANGE_IN_SECONDS
    ) {
      disableSeconds = FIRST_LEVEL_DISABLE_TIME_IN_SECONDS
    }
    if (
      errorsCount.current === SECOND_LEVEL_ATTEMPTS_COUNT &&
      timeFromLastLevelFirstAttempt < SECOND_LEVEL_TIME_RANGE_IN_SECONDS
    ) {
      disableSeconds = SECOND_LEVEL_DISABLE_TIME_IN_SECONDS
    }
    if (errorsCount.current === THIRD_LEVEL_ATTEMPTS_COUNT) {
      setIsLastLevelBruteforceProtect(true)

      if (timeFromLastLevelFirstAttempt < THIRD_LEVEL_TIME_RANGE_IN_SECONDS) {
        disableSeconds = THIRD_LEVEL_DISABLE_TIME_IN_SECONDS
      }
    }

    if (lastLevelFirstAttemptTime) {
      setBruteforceSecondsLeft((currentValue) => {
        if (disableSeconds > 0 || currentValue > 0) {
          setBruteforceProtectIsActive(true)
        }

        return currentValue || disableSeconds
      })
    }

    cookies.set(STORE_ATTEMPTS_COUNT_KEY, errorsCount.current, {
      maxAge: THIRD_LEVEL_TIME_RANGE_IN_SECONDS,
    })
  }, [errorsCount.current])

  useEffect(() => {
    if (bruteforceProtectIsActive && !bruteforceTimerIsActive) {
      startTimer(bruteforceSecondsLeft - 1)
      setBruteforceTimerIsActive(true)
    }
  }, [bruteforceProtectIsActive, bruteforceSecondsLeft, bruteforceTimerIsActive])

  useEffect(() => {
    if (submitting) {
      errorsCount.current += 1
    }
  }, [submitting, hasValidationErrors])

  return { bruteforceProtectIsActive, bruteforceSecondsLeft }
}

export default useBase
