export const isValidUrl = (urlString: string) => {
  try {
    const validUrl = new URL(urlString)

    if (!validUrl.origin) {
      return false
    }

    return Boolean(['localhost', 'dasreda.ru'].find((domain) => validUrl.hostname.includes(domain)))
  } catch {
    return false
  }
}
