// названия "зашифрованы", чтобы было не понятно, что в них
export const STORE_ATTEMPTS_COUNT_KEY = '_sack'
export const STORE_SECONDS_LEFT_KEY = '_sslk'
export const STORE_LAST_LEVEL_FIRST_ATTEMPT_TIME_KEY = '_sllfatk'

// интервалы в течение которых происходит отлов ошибок
export const FIRST_LEVEL_TIME_RANGE_IN_SECONDS = 60 * 2
export const SECOND_LEVEL_TIME_RANGE_IN_SECONDS = 60 * 5
export const THIRD_LEVEL_TIME_RANGE_IN_SECONDS = 60 * 10

// количество ошибок на каждый интервал, после которых появится таймер блокировки
export const FIRST_LEVEL_ATTEMPTS_COUNT = 5
export const SECOND_LEVEL_ATTEMPTS_COUNT = 10
export const THIRD_LEVEL_ATTEMPTS_COUNT = 20

// время работы таймера блокировки на каждый интервал
export const FIRST_LEVEL_DISABLE_TIME_IN_SECONDS = 60
export const SECOND_LEVEL_DISABLE_TIME_IN_SECONDS = 60 * 10
export const THIRD_LEVEL_DISABLE_TIME_IN_SECONDS = 60 * 30
