import { FC, useMemo } from 'react'

import { IFooter } from '../types'

import { SDsAgree } from '../styled.index'

interface IDsAgreeProps {
  actionType?: IFooter['actionType']
}

const DsAgree: FC<IDsAgreeProps> = ({ actionType }) => {
  const actionText = useMemo(() => {
    switch (actionType) {
      case 'login':
        return 'входе'
      case 'registration':
        return 'регистрации'
      default:
        return 'входе и регистрации'
    }
  }, [actionType])

  return (
    <SDsAgree>
      <div>
        При {actionText} вы принимаете условия{' '}
        <a
          href="https://cdn.dasreda.ru/fonts-static/polzovatelskoe_soglashenie.pdf"
          rel="noopener noreferrer"
          target="_blank"
          download
        >
          Пользовательского соглашения
        </a>
      </div>
      <div>
        <a
          href="https://cdn.dasreda.ru/storage-data/7577f37a-d9d6-412d-8358-0fd7423929b6/politika_obrabotki_pdn_30062023.pdf"
          rel="noopener noreferrer"
          target="_blank"
          download
        >
          Политика конфиденциальности
        </a>
      </div>
    </SDsAgree>
  )
}

export default DsAgree
