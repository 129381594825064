import { Link } from '@dasreda/components'
import styled, { css } from 'styled-components'

export const SMailProviderButton = styled(Link)(
  ({ theme, type }) => css`
    > span {
      color: ${theme.palette.textGraphite};
      font: ${theme.fontSystem.large};
      box-shadow: ${type === 'link' ? 'none' : `inset 0 0 0 1px ${theme.palette.border}`};

      > span:first-child {
        width: auto;
        margin-right: 8px;
        margin-left: 0;

        svg {
          position: relative;
          top: unset;
          left: unset;
          display: block;
          min-width: auto;
          min-height: auto;
          transform: none;
        }
      }
    }
  `
)
