import { noop } from '@dasreda/helpers'
import getApolloClient from 'gql/helpers/getApolloClient'
import {
  studyGroupStudentRegistration,
  studyGroupStudentRegistrationVariables,
} from 'gql/mutations/generated/studyGroupStudentRegistration'
import { loader } from 'graphql.macro'
import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import has from 'lodash/has'
import omit from 'lodash/omit'

import DEFAULT_AGREEMENT_TYPES from 'configs/agreementTypes'

import { ReactComponent as MobileAppIllustration } from 'assets/svg/mobile-app.svg'
import { Footer, Header } from 'components'
import { DATALAYER_CUSTOM_EVENT, DATALAYER_EVENT_REGISTRATION } from 'configs/dataLayer'
import {
  cityFieldProps,
  emailFieldProps,
  middleNameFieldProps,
  nameFieldProps,
  phoneFieldProps,
  surnameFieldProps,
} from 'configs/defaultFieldsProps'
import { TFormConfigFn } from 'containers/FormBuilder'
import { redirect } from 'utils/redirect'
import { commonRegistrationActions } from 'utils/registration'
import { dataLayerHandler } from 'utils/sendDataLayer'
import { email, phone, required } from 'utils/validators'

const studyGroupStudentRegistrationQuery = loader('src/gql/mutations/studyGroupStudentRegistration.gql')

const domclickRegistration: TFormConfigFn<{ studyGroupId: string }> = ({
  location: {
    query: { studyGroupId },
  },
}) => ({
  title: 'Регистрация на программу обучения Домклик',
  titleLevel: 2,
  leftColumn: <MobileAppIllustration />,
  formWidth: 450,
  submitButtonText: 'Зарегистрироваться',
  header: <Header type="registration-domclick" />,
  footer: <Footer type="dsAgree" actionType="registration" />,
  fields: [
    {
      ...surnameFieldProps,
      name: 'lastname',
      size: 'medium',
      validators: [required, ...(surnameFieldProps.validators as [])],
    },
    {
      ...nameFieldProps,
      name: 'firstname',
      size: 'medium',
      validators: [required, ...(nameFieldProps.validators as [])],
    },
    {
      ...middleNameFieldProps,
      name: 'middlename',
      size: 'medium',
      validators: [required, ...(middleNameFieldProps.validators as [])],
    },
    {
      ...phoneFieldProps,
      name: 'phone',
      size: 'medium',
      validators: [required, phone],
    },
    {
      ...emailFieldProps,
      name: 'email',
      size: 'medium',
      validators: [required, email],
    },
    {
      ...cityFieldProps,
      name: 'city',
      size: 'medium',
      validators: [required],
    },
  ],
  successMessage: 'На указанный вами e-mail отправлено письмо со ссылкой для подтверждения регистрации',
  postRender: noop,
  agreementTypesToLoad: DEFAULT_AGREEMENT_TYPES,
  defaultTruthForAgreementTypes: DEFAULT_AGREEMENT_TYPES,
  onRequest: async (values) => {
    const params = omit(cloneDeep(values), 'agreements') as studyGroupStudentRegistrationVariables['params']

    const apolloClient = await getApolloClient

    await apolloClient.mutate<studyGroupStudentRegistration, studyGroupStudentRegistrationVariables>({
      mutation: studyGroupStudentRegistrationQuery,
      variables: {
        id: studyGroupId,
        params,
      },
      fetchPolicy: 'network-only',
    })

    return {
      data: params,
    }
  },
  postHook: (_, response, { history: { push } }) => {
    if (response) {
      commonRegistrationActions()

      dataLayerHandler({
        event: DATALAYER_CUSTOM_EVENT,
        eventCategory: DATALAYER_EVENT_REGISTRATION,
        eventAction: studyGroupId,
      })

      if (has(response, 'data.email')) {
        const userEmail = get(response, 'data.email')

        redirect({ push, url: '/' }, { userEmail })
      }
    }
  },
})

export default domclickRegistration
