import { FC } from 'react'
import { Link as LinkIcon } from '@dasreda/icons'

import {
  SAgreementsIconWrap,
  SAgreementsLink,
  SAgreementsText,
  SAgreementsTitle,
  SAgreementsWrap,
} from './styled.index'

type TAgreement = {
  title: string
  href: string
}

interface IAgreements {
  list: TAgreement[]
}

const Agreements: FC<IAgreements> = ({ list }) => {
  if (!list.length) {
    return null
  }

  return (
    <SAgreementsWrap>
      <SAgreementsTitle level={2}>Регистрация на Платформе знаний и сервисов</SAgreementsTitle>
      <SAgreementsText>Ознакомьтесь с условиями регистрации и обработки персональных данных</SAgreementsText>
      {list.map(({ title, href }) => (
        <SAgreementsLink href={href} key={href} title={title} target="_blank" rel="noopener noreferrer">
          <SAgreementsIconWrap>
            <LinkIcon />
          </SAgreementsIconWrap>
          <span>{title}</span>
        </SAgreementsLink>
      ))}
    </SAgreementsWrap>
  )
}

export default Agreements
