import { FC } from 'react'

import { Agreements } from 'components'

export const list = [
  {
    title: 'Согласие для входа на Платформу знаний и сервисов dasreda.ru',
    href: 'https://cdn.dasreda.ru/storage-data/6be64f3f-6257-4157-9fd0-ed6394f3e4e5/soglasie_na_obrabotku_pdn_vop.pdf?_ga=2.218895065.199172975.1592201178-1520506596.1571065319',
  },
  {
    title: 'Правила использования Платформы знаний и сервисов dasreda.ru',
    href: 'https://cdn.dasreda.ru/fonts-static/polzovatelskoe_soglashenie.pdf',
  },
]

const AgreementsRBO: FC = () => <Agreements list={list} />

export default AgreementsRBO
