/* eslint-disable simple-import-sort/imports */
import { ApolloProvider } from '@apollo/client'
import { getTheme, GridThemeProvider } from '@dasreda/components'

import App from 'containers/App'
import getApolloClient from 'gql/helpers/getApolloClient'
import 'react-app-polyfill/ie11'
/* eslint-enable simple-import-sort/imports */
import { CookiesProvider } from 'react-cookie'
import { createRoot } from 'react-dom/client'
import GoogleTagManager from 'react-gtm-module'
import { Router } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import history from 'utils/history'

const apolloClient = getApolloClient

const theme = getTheme({})

const coopTheme = {
  ...theme,
  palette: {
    ...theme.palette,
    emerald: '#107F8C',
    contentTypeTrajectory: '#107F8C',
    contentTypeNews: '#107F8C',
    gradientGreen: '#107F8C',
    green: '#107F8C',
    cardBorderHover: '#107F8C',
    emeraldHover: '#21A19A',
    greenHover: '#21A19A',
    emeraldLight: '#FFFFFF',
    emeraldDark: '#005E7F',
    greenDark: '#005E7F',
    emeraldBg: '#90D0CC',
    emeraldBorder: '#005E7F',
    textGraphite: '#1F1F22',
    textBlack: '#1F1F22',
    border: '#D0D7DD',
    disabled: '#D0D7DD',
    textGray: '#7D838A',
    cardBorder: '#7D838A',
    skyText: '#1358BF',
    skyBg: '#F2F8FF',
    rubyText: '#C11030',
    pink: '#C11030',
    contentTypeVideo: '#C11030',
    red: '#C11030',
    rubyBg: '#FFE5E8',
    sberId: '#21A038',
  },
}

if (process.env.NODE_ENV === 'production') {
  GoogleTagManager.initialize({
    gtmId: 'GTM-N6NG2VP',
    events: {
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    },
  })
}

apolloClient.then((client) => {
  const container = document.getElementById('root')

  if (!container) throw new Error('Root element is not created')

  console.log('NEW VERSION!', 3)

  const root = createRoot(container)
  root.render(
    <CookiesProvider>
      <Router history={history}>
        <GridThemeProvider gridTheme={theme.grid}>
          <ThemeProvider theme={process.env.REACT_APP_BUILD === 'coop' ? coopTheme : theme}>
            <ApolloProvider client={client}>
              <App />
            </ApolloProvider>
          </ThemeProvider>
        </GridThemeProvider>
      </Router>
    </CookiesProvider>
  )
})
