import React from 'react'

import { SDsAgree } from '../styled.index'

const CoopAgree = () => (
  <SDsAgree>
    Регистрируясь, вы даете Согласие
    <br />
    на обработку персональных данных
    <br />и принимаете{' '}
    <a
      href="https://cdn.dasreda.ru/fonts-static/coop-offer-for-board-chairman.pdf"
      rel="noopener noreferrer"
      target="_blank"
      download
    >
      Оферту об использовании сервиса “Кооператив Онлайн”
    </a>
  </SDsAgree>
)

export default CoopAgree
