import qs from 'qs'
import { TResUser } from 'types/user'

import DEFAULT_AGREEMENT_TYPES from 'configs/agreementTypes'

import { Footer, Header } from 'components'
import { DATALAYER_CUSTOM_EVENT, DATALAYER_EVENT_AUTHORIZATION } from 'configs/dataLayer'
import { TFormConfig, TFormConfigFn } from 'containers/FormBuilder'
import OAuthBtns from 'containers/OAuth/containers/Buttons'
import { prepareAgreementTypesToUpdateForUser, updateAgreements } from 'utils/agreements'
import { login } from 'utils/auth'
import getFormFieldsByType from 'utils/getFormFieldsByType'
import { sendNewUserToGTR } from 'utils/getResponse'
import { redirect, redirectPreRenderHook } from 'utils/redirect'
import { dataLayerHandler } from 'utils/sendDataLayer'
import { passwordSimpleValidator, phone as phoneValidator, required } from 'utils/validators'

import { passwordFieldProps, phoneFieldProps } from '../../defaultFieldsProps'

const types = {
  oAuth: {
    withLogo: false,
    successRedirect: '/profile-change',
  },
}

type TPartialFormConfig = Partial<Pick<TFormConfig, 'title' | 'header' | 'withLogo'>>
interface IReturnConfig extends TPartialFormConfig {
  successRedirect?: string
}

const loginByPhone: TFormConfigFn<{ type: string }> = ({
  location: {
    query: { type },
  },
}) => {
  const {
    title = 'Войти',
    header = <Header type="login-by-phone" />,
    withLogo = true,
    successRedirect,
  } = getFormFieldsByType<IReturnConfig>(types)(type)

  return {
    title,
    submitButtonText: 'Войти',
    header,
    insideFooter: <Footer type="forgotPasswordPhone" align="right" />,
    footer: <OAuthBtns profile="DASREDA" />,
    withLogo,
    fields: [
      {
        ...phoneFieldProps,
        name: 'username',
        validators: [required, phoneValidator],
        fieldSuffix: 'loginByPhone',
      },
      {
        ...passwordFieldProps,
        validators: [required, passwordSimpleValidator],
      },
    ],
    verticalAlign: 'flex-start',
    agreementTypesToLoad: DEFAULT_AGREEMENT_TYPES,
    postRender: redirectPreRenderHook,
    onRequest: (values) => {
      const config = {
        location: 'single-auth:login',
        params: {
          data: values,
        },
      }

      return login(config)
    },
    postHook: async (_, response, { location: { query }, history: { push } }, loadedAgreementDocuments) => {
      if (response) {
        const { data: user } = response as TResUser
        const { id, email, phone, firstName, lastName, publicName, channelId } = user

        if (id) {
          dataLayerHandler({
            event: DATALAYER_CUSTOM_EVENT,
            eventCategory: DATALAYER_EVENT_AUTHORIZATION,
            eventAction: channelId,
            userId: id,
          })
        }

        await sendNewUserToGTR(user)

        let successRedirectWithParams = successRedirect
        if (successRedirect && !(email && phone && publicName)) {
          const queryParams = {
            ...query,
            email,
            phone,
            firstName,
            lastName,
            publicName,
            form: 'login',
          }

          successRedirectWithParams += `?${qs.stringify(queryParams)}`
        } else successRedirectWithParams = ''

        const { agreementTypesToUpdate, agreementDocuments } = await prepareAgreementTypesToUpdateForUser(
          DEFAULT_AGREEMENT_TYPES,
          loadedAgreementDocuments
        )

        if (agreementTypesToUpdate.length > 0) {
          await updateAgreements({ agreementTypesToUpdate, agreementDocuments })
        }

        redirect({ push, url: successRedirectWithParams })
      }
    },
  }
}

export default loginByPhone
