import { Cookies } from 'react-cookie'
import axios from 'axios'

import { ISbbolParams } from '../containers/Consumer/types'

import { configs } from 'eco-api'

import { REDIRECT_URL, SBBOL_TOKEN_COOKIE_NAME } from 'configs/consts'
import { request } from 'utils/api'
import getClientId from 'utils/getClientId'

import { TSetMessage } from '../components/ResultPage'
import { successLogin } from '../containers/Consumer/utils'

interface IResAuthByCodeInput {
  db_schema: string
  clientId?: string
}

const { AUTH_COOKIE_DOMAIN } = configs

const onLoadSbbol =
  ({ code, push, product, redirectUrl }: ISbbolParams) =>
  async (setError: TSetMessage) => {
    if (!code) {
      setError('Отсутствует обязательный параметр — code')
      return
    }

    try {
      const clientId = await getClientId(product)

      if (product && !clientId) {
        setError('Авторизация пользователя завершилась с ошибкой')
        return
      }

      const params: IResAuthByCodeInput = {
        db_schema: 'public',
      }

      if (clientId) {
        params.clientId = clientId
      }

      // На бекенде происходит регистрация пользователя по каналу OAS
      const resAuthByCode = await request({
        location: `single-auth:sbbol/user/authorize/${code}`,
        method: 'get',
        params: { params },
      })

      const cookies = new Cookies()

      const tokenAuth = resAuthByCode?.data?.token
      const accessTokenSbbol = resAuthByCode?.data?.access_token

      cookies.set(SBBOL_TOKEN_COOKIE_NAME, accessTokenSbbol, {
        domain: AUTH_COOKIE_DOMAIN,
        path: '/',
      })

      if (redirectUrl) {
        localStorage.setItem(REDIRECT_URL, redirectUrl)
      }

      if (tokenAuth) {
        await successLogin(resAuthByCode, push)
      }
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const { error } = err.response?.data

        setError(error ? `${error.message}. ${error.description}` : 'Авторизация пользователя завершилась с ошибкой')
      }
    }
  }

export default onLoadSbbol
