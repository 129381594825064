import { Logo } from '@dasreda/icons'
import styled, { css } from 'styled-components'

export const SKmSbscrSubmit = styled.div(
  () => css`
    margin-top: 24px;

    p {
      margin: 8px auto 16px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  `
)

export const SKmSbscrConfirmCode = styled.div(
  () => css`
    margin: -8px 0 16px;
  `
)

export const SLogo = styled(Logo)(
  () => css`
    height: 64px;
    margin-bottom: 12px;
  `
)
