import getApolloClient from 'gql/helpers/getApolloClient'
import { loader } from 'graphql.macro'
import reduce from 'lodash/reduce'

import {EXCLUDED_AGREEMENT_TYPES_PLATFORM} from "../configs/agreementTypes"

import listOfAgreements, {DUMMY_AGREEMENT} from 'configs/agreements'

import { request } from './api'

const agreementDocumentsGQL = loader('src/gql/queries/agreementDocuments.gql')
const agreementsGQL = loader('src/gql/queries/agreements.gql')

// преобразует массив согласий в понятный беку формат
const transformAgreements = (obj, agreementDocuments = {}) =>
  reduce(
    obj,
    (result, val, key) => {
      if (listOfAgreements[key]) {
        if (!result.agreements) {
          // eslint-disable-next-line no-param-reassign
          result.agreements = []
        }

        const agreement = {
          type: key,
          agree: !!val,
        }
        const agreementDocument = agreementDocuments[key]
        if (agreementDocument) {
          agreement.docDate = agreementDocument.docDate
        }

        result.agreements.push(agreement)
      } else if (key !== DUMMY_AGREEMENT) {
        // eslint-disable-next-line no-param-reassign
        result[key] = val
      }

      return result
    },
    {}
  )

const getUserAgreements = () =>
  request({
    location: `core-router/v2:agree`,
    method: 'get',
  })

const updateUserAgreementRequest = (agreement) => {
  request({
    location: 'core-router/v2:agree',
    method: 'post',
    params: {
      data: agreement,
    },
  })
}

const updateUserAgreementsRequest = (agreements) =>
  request({
    location: 'core-router/v2:agrees',
    method: 'post',
    params: {
      data: agreements,
    },
  })

const loadAgreementDocuments = async (agreementTypes) => {
  const apolloClient = await getApolloClient
  const { data } = await apolloClient.query({
    query: agreementDocumentsGQL,
    variables: {
      agreementTypes,
    },
  })

  const agreementDocumentsList = data?.agreementDocuments || []

  const agreementDocuments = agreementDocumentsList.reduce((result, agreementDocument) => {
    // eslint-disable-next-line no-param-reassign
    result[agreementDocument.agreementType] = agreementDocument
    return result
  }, {})

  return agreementDocuments
}

const loadUserAgreements = async () => {
  const apolloClient = await getApolloClient
  const { data } = await apolloClient.query({
    query: agreementsGQL,
  })

  return data?.agreements || []
}

const prepareAgreementTypesToUpdate = (agreementTypes, agreementDocuments, userAgreements) => {
  const agreementTypesToUpdate = agreementTypes.filter((agreementType) => {
    const userAgreement = userAgreements.find((agreement) => agreement.type === agreementType)
    const agreementDocument = agreementDocuments[agreementType]

    if (!agreementDocument) {
      return false
    }

    /** Если тип соглашения есть в константе с исключениями, то его не добавляем в массив типов для обновления * */
    if (!EXCLUDED_AGREEMENT_TYPES_PLATFORM[userAgreement?.type]){
      return false
    }

    if (!userAgreement || !userAgreement.docDate) {
      return true
    }

    if (new Date(agreementDocument.docDate) > new Date(userAgreement.docDate)) {
      return true
    }
    return false
  })

  return agreementTypesToUpdate
}

const prepareAgreementTypesToUpdateForUser = async (agreementTypes, loadedAgreementDocuments) => {
  /** Получаем все типы соглашений, с помощью мёржа типов соглашений из agreementTypes и соглашений,
   * которые уже есть у юзера. * */
  const userAgreements = await loadUserAgreements()

  const uniqUserAgreementTypes = userAgreements
    .map((agreement) => agreement.type)
    .filter((agreementType) => !agreementTypes.includes(agreementType))

  let agreementDocuments
  if (uniqUserAgreementTypes.length > 0) {
    const userAgreementDocuments = await loadAgreementDocuments(uniqUserAgreementTypes)
    agreementDocuments = { ...loadedAgreementDocuments, ...userAgreementDocuments }
  } else {
    agreementDocuments = loadedAgreementDocuments
  }

  /** Определяем соглашения, которые необходимо обновить * */
  const agreementTypesToUpdate = prepareAgreementTypesToUpdate(
    [...agreementTypes, ...uniqUserAgreementTypes],
    agreementDocuments,
    userAgreements
  )

  return { agreementTypesToUpdate, agreementDocuments }
}

const updateAgreements = async ({ agreementTypesToUpdate, agreementDocuments }) => {
  const agreements = agreementTypesToUpdate.map((agreementType) => {
    const agreementDocument = agreementDocuments[agreementType]

    return {
      type: agreementType,
      agreed: true,
      docDate: agreementDocument.docDate,
    }
  })

  await updateUserAgreementsRequest(agreements)
}

export {
  updateAgreements,
  transformAgreements,
  getUserAgreements,
  updateUserAgreementRequest,
  updateUserAgreementsRequest,
  loadAgreementDocuments,
  loadUserAgreements,
  prepareAgreementTypesToUpdate,
  prepareAgreementTypesToUpdateForUser,
}
