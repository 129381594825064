import qs from 'qs'
import { TResUser } from 'types/user'

import { IDefaultFormConfigFnProps } from '../types'
import { ISingleAuthLoginData } from './types'
import DEFAULT_AGREEMENT_TYPES from 'configs/agreementTypes'

import { configs } from 'eco-api'

import { Alert, Footer, Header } from 'components'
import { MSP_DISPATCH, SUBSCRIPTION_DISPATCH } from 'configs/agreements'
import { DATALAYER_CUSTOM_EVENT, DATALAYER_EVENT_AUTHORIZATION } from 'configs/dataLayer'
import { TFormConfig, TFormConfigFn } from 'containers/FormBuilder'
import { TField, TValues } from 'containers/FormBuilder/components/Field'
import OAuthBtns from 'containers/OAuth/containers/Buttons'
import { prepareAgreementTypesToUpdateForUser, updateAgreements, updateUserAgreementRequest } from 'utils/agreements'
import applyDelayedPd from 'utils/applyDelayedPd'
import { login } from 'utils/auth'
import getFormFieldsByType from 'utils/getFormFieldsByType'
import { sendNewUserToGTR } from 'utils/getResponse'
import { redirect, redirectPreRenderHook } from 'utils/redirect'
import { dataLayerHandler } from 'utils/sendDataLayer'
import { email as emailValidator, passwordSimpleValidator, required } from 'utils/validators'

import { emailFieldProps, mspAgreements, passwordFieldProps, subscriptionAgree } from '../../../defaultFieldsProps'

import SubmitBlock from './components/SubmitBlock'

const env = configs.resolveEnvironment()

const config = {
  oAuth: {
    header: <Header type="login-by-email" />,
    withLogo: false,
    successRedirect: '/profile-change',
  },
  subscription: {
    header: <Header type="login-by-email" />,
    title: 'Войдите или зарегистрируйтесь для продолжения покупки',
    initialValues: { [SUBSCRIPTION_DISPATCH]: true },
    extraFields: [
      {
        ...subscriptionAgree,
        validators: [required],
      },
    ],
  },
  purchase: {
    title: 'Войдите или зарегистрируйтесь для продолжения покупки',
  },
  course_access: {
    title: 'Для доступа к уроку необходимо авторизоваться',
  },
  msp: {
    initialValues: { [MSP_DISPATCH]: true },
    extraFields: [
      {
        ...mspAgreements,
        validators: [required],
      },
    ],
  },
}

type TPartialFormConfig = Partial<Pick<TFormConfig, 'title' | 'header' | 'withLogo'>>

interface IReturnConfig extends TPartialFormConfig {
  successRedirect?: string
  initialValues?: TValues
  extraFields?: TField[]
}

const defaultLogin: TFormConfigFn<IDefaultFormConfigFnProps> = ({
  location: {
    query: { type, system, applypd },
  },
}) => {
  const {
    title = 'Войти',
    header = (
      <>
        <div style={{ height: '16px' }} />
        <Alert type="warning" text="Перед авторизацией выключите VPN" />
        <div style={{ marginBottom: '-16px' }} />
        <Header type="login" />
      </>
    ),
    withLogo = true,
    successRedirect,
    extraFields = [],
    initialValues = {},
  } = getFormFieldsByType<IReturnConfig>(config)(type)

  const isLMS = system?.toUpperCase() === 'LMS'

  const fields = [
    {
      ...emailFieldProps,
      validators: [required, emailValidator],
      fieldSuffix: isLMS ? undefined : 'loginDefault',
    },
    {
      ...passwordFieldProps,
      validators: [required, passwordSimpleValidator],
    },
    ...extraFields,
  ]

  return {
    title,
    submitButtonText: 'Войти',
    header,
    initialValues,
    insideFooter: <Footer type="forgotPassword" align="right" />,
    footer: isLMS ? undefined : <OAuthBtns profile="DASREDA" />,
    submitBlock: SubmitBlock,
    withLogo,
    verticalAlign: 'flex-start',
    fields,
    agreementTypesToLoad: DEFAULT_AGREEMENT_TYPES,
    postRender: redirectPreRenderHook,
    onRequest: async ({ password, username }) => {
      const data: ISingleAuthLoginData = { password, username }

      if (applypd === 'true') {
        data.applypd = applypd
      }

      const reqParams = {
        location: 'single-auth:login',
        params: {
          withCredentials: true,
          data,
        },
      }

      return login(reqParams)
    },
    postHook: async (
      _,
      response,
      { location: { query }, history: { push } },
      { subscription_dispatch },
      loadedAgreementDocuments
    ) => {
      if (response) {
        const { data: user } = response as TResUser
        const { id, email, phone, firstName, lastName, publicName, channelId, token } = user
        const isApplyPd = applypd === 'true'

        if (id) {
          dataLayerHandler({
            event: DATALAYER_CUSTOM_EVENT,
            eventCategory: DATALAYER_EVENT_AUTHORIZATION,
            eventAction: channelId || 'null',
            userId: id,
          })
        }

        if (isApplyPd && token) {
          await applyDelayedPd(token)
        }

        await sendNewUserToGTR(user)

        // для типа подписки
        if (subscription_dispatch) {
          await updateUserAgreementRequest({ type: SUBSCRIPTION_DISPATCH, agreed: subscription_dispatch })
        }

        let successRedirectWithParams = successRedirect
        let urlParams = ''

        if (successRedirect && !(email && phone && publicName)) {
          const queryParams = {
            ...query,
            email,
            phone,
            firstName,
            lastName,
            publicName,
            form: 'login',
          }

          urlParams = qs.stringify(queryParams)

          successRedirectWithParams += `?${urlParams}`
        } else {
          successRedirectWithParams = undefined
        }

        /** Подготавливаем типы соглашений юзера, которые необходимо обновить * */
        const { agreementTypesToUpdate, agreementDocuments } = await prepareAgreementTypesToUpdateForUser(
          DEFAULT_AGREEMENT_TYPES,
          loadedAgreementDocuments
        )

        if (agreementTypesToUpdate.length > 0) {
          await updateAgreements({ agreementTypesToUpdate, agreementDocuments })
        }

        if (isApplyPd) {
          redirect({
            push,
            url: `https://${env === 'prod' ? '' : `${env}.`}dasreda.ru/user-profile/settings${
              urlParams ? `?${urlParams}` : ''
            }`,
          })
        } else {
          redirect({ push, url: successRedirectWithParams })
        }
      }
    },
  }
}

export default defaultLogin
