import { FC, useEffect, useMemo, useRef, useState } from 'react'
import { Form } from 'react-final-form'
import { RouteComponentProps } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'
import { Config, Decorator } from 'final-form'
import createDecoratorCalc, { Calculation } from 'final-form-calculate'
import createDecoratorFocus from 'final-form-focus'
import {
  agreementDocuments as TAgreementDocumentsRes,
  agreementDocuments_agreementDocuments_AgreementDocument as TAgreementDocument,
  agreementDocumentsVariables,
} from 'gql/queries/generated/agreementDocuments'
import { loader } from 'graphql.macro'
import { History, Location, LocationState } from 'history'
import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'
import noop from 'lodash/noop'
import pickBy from 'lodash/pickBy'
import reduce from 'lodash/reduce'

import agreements from 'configs/agreements'
import { ACTION_SUBMIT, commonError } from 'configs/consts'
import { transformAgreements } from 'utils/agreements'
import { transformError } from 'utils/transformError'

import { TValues } from './components/Field'
import RenderForm, { IRenderForm } from './components/Form'

const agreementDocumentsGQL = loader('src/gql/queries/agreementDocuments.gql')

// используется для фокуса на первом hasError поле
const focusOnErrors = createDecoratorFocus()

type TAgreementsList = keyof typeof agreements

type TCustomLocationProps<ObjParams> = {
  query: ObjParams
}

type TCustomLocation<ObjParams> = {
  location: Location<LocationState> & TCustomLocationProps<ObjParams>
}

export type TPreparedAgreeDocuments = { [key: string]: TAgreementDocument }

export interface ICaptchaInfo {
  errorCount: number
  maxErrorCount?: number
}

interface IFormBuilder {
  // Сохраняет значение полей после успешного submit'a
  // Принимает массив имен полей, ['email', 'password']
  keepFields?: string[]
  // Отрисовывает логотип вверху формы
  withLogo?: boolean
  // Устанавливает значение полей при инициализации формы
  initialValues?: TValues
  // Декораторы для полей формы
  decorators?: Calculation[]
  // Функция будет вызвана после первого рендера формы в useEffect
  postRender?: ({
    location,
    history,
  }: {
    history: History<LocationState>
  } & TCustomLocation<{ action: string; redirect: string }>) => void
  // Функция будет вызвана в момент submit'а перед onRequest
  preHook?: () => void
  // Функция будет вызвана в момент submit'а формы
  // @return - Promise, запрос к серверу
  onRequest: (values: TValues) => Promise<unknown> | void
  // Функция будет вызвана после выполнения onRequest, в любом случае
  // @param - Object, error || null, объект ошибки от сервера
  // @param - Object, response || null, объект успешного ответа от сервера
  // @param - Object, свойства компонента формы
  // @param - Object, { key: value }, где key - name атрибут поля, значение полей формы
  // @return - Promise, можно вернуть Promise с кастомным объектом ошибки
  postHook?: (
    error: unknown | null,
    response: unknown | null,
    formParams: {
      history: History<LocationState>
      initialValues: TValues
    } & TCustomLocation<{ action: string; redirect: string }>,
    values: TValues,
    agreementDocuments: TPreparedAgreeDocuments
  ) => Promise<unknown> | void
  // Типы соглашений, документы которых нужно предварительно загрузить
  agreementTypesToLoad?: TAgreementsList[]
  // Типы соглашений, которые устанавливаются по умолчанию при сабмите формы
  defaultTruthForAgreementTypes?: TAgreementsList[]
}

export type TFormConfig = IFormBuilder & IRenderForm
export type TFormConfigFn<QueryObj = Record<string, never>> = (
  props: RouteComponentProps & TCustomLocation<QueryObj>
) => IFormBuilder & IRenderForm

const FormBuilder: FC<TFormConfig & RouteComponentProps & TCustomLocation<{ action: string; redirect: string }>> = ({
  // IFormBuilder
  keepFields = [],
  initialValues: propsInitialValues = {},
  decorators = [],
  postRender = noop,
  preHook = noop,
  onRequest,
  postHook = () => {},
  agreementTypesToLoad = [],
  defaultTruthForAgreementTypes = [],
  // RouteComponentProps
  match,
  history,
  // TCustomLocation
  location,
  // IRenderForm
  fields,
  resetCaptchaCount,
  ...formComponentParams
}) => {
  const { params } = match
  const { query } = location
  const { action } = query

  const [agreementDocuments, setAgreementDocuments] = useState<TPreparedAgreeDocuments>({})
  const [captchaInfo, setCaptchaInfo] = useState<ICaptchaInfo>({ errorCount: 0, maxErrorCount: resetCaptchaCount })
  const [loadAgreements, { data: agreementsRes }] = useLazyQuery<TAgreementDocumentsRes, agreementDocumentsVariables>(
    agreementDocumentsGQL,
    {
      variables: {
        agreementTypes: agreementTypesToLoad,
      },
      fetchPolicy: 'cache-first',
    }
  )

  const submitFn = useRef(noop)

  useEffect(() => {
    if (agreementTypesToLoad?.length > 0) {
      loadAgreements()
    }

    postRender({ location, history })

    if (action === ACTION_SUBMIT) {
      submitFn.current()
    }
  }, [])

  useEffect(() => {
    if (agreementsRes?.agreementDocuments) {
      const agreementDocumentsList = agreementsRes.agreementDocuments || []

      const preparedAgreementDocuments = reduce(
        agreementDocumentsList as TAgreementDocument[],
        (result, agreementDocument) => {
          // eslint-disable-next-line no-param-reassign
          result[agreementDocument.agreementType] = agreementDocument
          return result
        },
        {} as TPreparedAgreeDocuments
      )
      setAgreementDocuments(preparedAgreementDocuments)
    }
  }, [agreementsRes])

  type TOnSubmit = (initialValues: TValues) => Config['onSubmit']

  const onSubmit: TOnSubmit = (initialValues) => async (values, form) => {
    preHook()

    let cloneValues: TValues = cloneDeep(values)

    defaultTruthForAgreementTypes.forEach((agreementType) => {
      cloneValues[agreementType] = true
    })

    cloneValues = transformAgreements(cloneValues, agreementDocuments)

    try {
      const response = await onRequest(cloneValues)
      postHook(null, response, { location, history, initialValues }, cloneValues, agreementDocuments)

      let initializeState = initialValues
      if (keepFields.length)
        initializeState = keepFields.reduce((acc, key) => {
          acc[key] = (values as TValues)[key]
          return acc
        }, {} as TValues)
      return setTimeout(() => form.initialize(initializeState))
    } catch (error) {
      const hookError = await postHook(
        error,
        null,
        { location, history, initialValues },
        cloneValues,
        agreementDocuments
      )
      if (captchaInfo.maxErrorCount) {
        setCaptchaInfo({ ...captchaInfo, errorCount: captchaInfo.errorCount + 1 })
      }
      if (hookError) return hookError

      const formatErrors = transformError(error)
      return isEmpty(formatErrors) ? commonError : formatErrors
    }
  }

  // externalParams - переменные установленные в url
  const externalParams = { ...params, ...query }
  const mergedInitialValues: TValues = Object.assign(
    propsInitialValues,
    // Использую pickBy для того, чтобы исключить лишние параметры, которые потом едут в submit
    pickBy(externalParams, (_, key) => fields.map(({ name }) => name).includes(key))
  )

  const formDecorators = useMemo(() => [createDecoratorCalc(...decorators), focusOnErrors as Decorator], [])

  return (
    <Form
      onSubmit={onSubmit(mergedInitialValues)}
      decorators={formDecorators}
      initialValues={mergedInitialValues}
      subscription={{
        submitting: true,
        pristine: true,
        submitError: true,
        submitErrors: true,
        submitSucceeded: true,
        submitFailed: true,
        hasValidationErrors: true,
      }}
      render={(ownFormProps) => {
        submitFn.current = ownFormProps.handleSubmit
        return (
          <RenderForm
            {...{
              fields,
              history,
              ...formComponentParams,
              ...ownFormProps,
              agreementDocuments,
              resetCaptcha: { captchaInfo, setCaptchaInfo },
            }}
          />
        )
      }}
    />
  )
}

export default FormBuilder
