import { City } from 'components'
import { TField } from 'containers/FormBuilder/components/Field'
import { SCheckboxInput, SFieldInput } from 'containers/FormBuilder/styled.index'
import { normalizePhone } from 'utils/normalize'
import { maxLength, minLength, onlyCyrillicText } from 'utils/validators'

import { MSP_DISPATCH, PUBLISH_PD, SENDINGS_AGREEMENT, SUBSCRIPTION_DISPATCH } from './agreements'

export const cityFieldProps: TField = {
  name: 'city',
  component: City,
  placeholder: 'Город',
  qa: 'city_field',
}

export const emailFieldProps: TField = {
  name: 'username',
  type: 'email',
  component: SFieldInput,
  placeholder: 'Электронная почта',
  qa: 'email_field',
  autoComplete: 'username',
}

export const passwordFieldProps: TField = {
  name: 'password',
  type: 'password',
  component: SFieldInput,
  placeholder: 'Пароль',
  qa: 'password_field',
  autoComplete: 'current-password',
  fieldSuffix: 'password',
}

export const passwordRepeatFieldProps: TField = {
  name: 'passwordMatch',
  type: 'password',
  component: SFieldInput,
  placeholder: 'Подтверждение пароля',
  qa: 'password_conf_field',
  fieldSuffix: 'password',
}

export const nameFieldProps: TField = {
  name: 'firstName',
  component: SFieldInput,
  placeholder: 'Имя',
  qa: 'firstName_field',
  validators: [onlyCyrillicText, minLength, maxLength],
}

export const surnameFieldProps: TField = {
  name: 'lastName',
  component: SFieldInput,
  placeholder: 'Фамилия',
  qa: 'lastName_field',
  validators: [onlyCyrillicText, minLength, maxLength],
}

export const middleNameFieldProps: TField = {
  name: 'middleName',
  component: SFieldInput,
  placeholder: 'Отчество',
  qa: 'middleName_field',
  validators: [onlyCyrillicText, minLength, maxLength],
}

export const innFieldProps: TField = {
  name: 'inn',
  component: SFieldInput,
  placeholder: 'ИНН',
  qa: 'inn_field',
}

export const phoneFieldProps: TField = {
  name: 'phone',
  type: 'phone',
  component: SFieldInput,
  placeholder: 'Телефон',
  qa: 'phone_field',
  mask: 'phone',
  parse: normalizePhone,
}

export const publishPdFieldProps: TField = {
  name: PUBLISH_PD,
  component: SCheckboxInput,
  label: 'Согласен на публикацию персональных данных?',
  qa: 'publishPd_checkbox',
}

export const subscriptionAgree: TField = {
  name: SUBSCRIPTION_DISPATCH,
  component: SCheckboxInput,
  label: 'Соглашаюсь на автоматическое списание денежных средств',
  qa: 'subscriptionDispatch_checkbox',
}

export const sendingsAgreement: TField = {
  name: SENDINGS_AGREEMENT,
  type: 'checkbox',
  component: SCheckboxInput,
  label: 'Согласен получать рассылки.',
  qa: 'sendings_checkbox',
}

export const mspAgreements: TField = {
  name: MSP_DISPATCH,
  component: SCheckboxInput,
  label: (
    <span>
      Авторизовавшись в системе, вы принимаете{' '}
      <a href="/registration-secondary/msp-agreements" target="_blank" rel="noopener noreferrer">
        Пользовательские соглашения
      </a>
    </span>
  ),
  qa: 'subscriptionDispatch_checkbox',
}
