import { AxiosError } from 'axios'
import qs from 'qs'
import { TResUser, TUser } from 'types/user'

import DEFAULT_AGREEMENT_TYPES from 'configs/agreementTypes'

import { TERMS_OF_USE_DS_DISPATCH } from 'configs/agreements'
import { commonError, dummyUser } from 'configs/consts'
import { DATALAYER_CUSTOM_EVENT, DATALAYER_EVENT_REGISTRATION } from 'configs/dataLayer'
import { TFormConfig } from 'containers/FormBuilder'
import SubmitButton from 'containers/FormBuilder/components/SubmitButton'
import { SCheckboxInput, SFieldInput } from 'containers/FormBuilder/styled.index'
import { request } from 'utils/api'
import { redirect } from 'utils/redirect'
import { dataLayerHandler } from 'utils/sendDataLayer'
import { email as emailValidator, personnelNumber, phone as phoneValidator, required } from 'utils/validators'

import CHANNEL_IDS from '../../../channels'
import { emailFieldProps, phoneFieldProps } from '../../../defaultFieldsProps'

const channelId = CHANNEL_IDS.KMS

type TAgreementTypes = [...typeof DEFAULT_AGREEMENT_TYPES, typeof TERMS_OF_USE_DS_DISPATCH]

const AGREEMENT_TYPES: TAgreementTypes = [...DEFAULT_AGREEMENT_TYPES, TERMS_OF_USE_DS_DISPATCH]

type TCustomParams = Required<Pick<TUser, 'email' | 'phone' | 'number'>> & {
  id?: TUser['id']
  'repeat-сode'?: TUser['repeat_code']
}

const getSuccessRedirectLink = (params: TCustomParams) =>
  `/registration-secondary/subscription/new-user?${qs.stringify(params)}`

const getFailRedirectLink = (params: TCustomParams) =>
  `/registration-secondary/subscription/existing-user?${qs.stringify(params)}`

const SubmitBlock: TFormConfig['submitBlock'] = ({ submitting, hasValidationErrors }) => {
  return (
    <SubmitButton type="submit" disabled={submitting || hasValidationErrors}>
      Зарегистрировать
    </SubmitButton>
  )
}

const kmSubscriptionRegistration: TFormConfig = {
  title: 'Регистрация нового пользователя Деловая Среда «Премиум»',
  submitBlock: SubmitBlock,
  keepFields: ['login', 'number'],
  fields: [
    {
      name: 'number',
      type: 'text',
      component: SFieldInput,
      placeholder: 'Табельный номер',
      qa: 'number_field',
      validators: [required, personnelNumber],
    },
    {
      ...emailFieldProps,
      validators: [required, emailValidator],
      name: 'email',
      placeholder: 'Email клиента',
    },
    {
      ...phoneFieldProps,
      validators: [required, phoneValidator],
      placeholder: 'Телефон клиента',
    },
    {
      name: TERMS_OF_USE_DS_DISPATCH,
      component: SCheckboxInput,
      label: (
        <span>
          Я принимаю условия{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://cdn.dasreda.ru/fonts-static/polzovatelskoe_soglashenie.pdf"
          >
            Пользовательского соглашения
          </a>{' '}
          и даю своё{' '}
          <a target="_blank" rel="noopener noreferrer" href="https://cdn.dasreda.ru/fonts-static/soglasie-pd.pdf">
            согласие
          </a>{' '}
          АО «Деловая среда» на обработку моих персональных данных, в соответствии с Федеральным законом от 27.07.2006
          года №152-ФЗ «О персональных данных», на условиях и для целей, определенных{' '}
          <a target="_blank" rel="noopener noreferrer" href="https://cdn.dasreda.ru/fonts-static/company-policy.pdf">
            Политикой конфиденциальности
          </a>
        </span>
      ),
      qa: 'dispatchDsAgree_checkbox',
      validators: [required],
    },
  ],
  agreementTypesToLoad: AGREEMENT_TYPES,
  defaultTruthForAgreementTypes: DEFAULT_AGREEMENT_TYPES,
  onRequest: (values) => {
    const config = {
      location: `core-router/v2:register/${channelId}`,
      params: {
        data: {
          ...dummyUser,
          ...values,
          channelId,
        },
      },
    }

    return request(config)
  },
  postHook: async (error, response, { history: { push } }, values) => {
    const { number, email, phone } = values
    const params = {
      email,
      phone,
      number,
    }

    if (!error && response) {
      const { id, repeat_code } = (response as TResUser).data
      const url = getSuccessRedirectLink({
        ...params,
        id,
        'repeat-сode': repeat_code,
      })

      dataLayerHandler({
        event: DATALAYER_CUSTOM_EVENT,
        eventCategory: DATALAYER_EVENT_REGISTRATION,
        eventAction: channelId,
        userId: id,
      })

      return redirect(
        { push, url },
        {
          isRedirect: true,
        }
      )
    }

    if ((error as AxiosError)?.response?.status === 409) {
      const url = getFailRedirectLink(params)

      return redirect(
        { push, url },
        {
          isRedirect: true,
        }
      )
    }

    return commonError
  },
}

export default kmSubscriptionRegistration
