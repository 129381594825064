import valid from './valid'

export const required = (value: string) => (value ? undefined : 'Поле обязательно к заполнению')
export const onlyCyrillicText = (value: string) => {
  if (!/^[-а-яА-ЯЁё]+(\s+[-а-яА-ЯЁё]+)*$/.test(value)) {
    return 'Разрешены только русские буквы'
  }

  return undefined
}
export const minLength = (value: string) => {
  if (value.length < 2) {
    return 'Введите 2 или более символов'
  }

  return undefined
}

export const maxLength = (value: string) => {
  if (value.length > 24) {
    return 'Поле может содержать не более 24 символов'
  }

  return undefined
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const equal = (value: string, allValues: { [key: string]: any }, key: string) =>
  value === allValues[key] ? undefined : 'Значения полей должны совпадать'

export const email = (value: string) => (valid.email(value) ? undefined : 'Введите корректный адрес эл. почты')

export const emailNotRequired = (value: string) =>
  value && !valid.email(value) ? 'Введите корректный адрес эл. почты' : undefined

export const passwordStrongValidator = (value: string) => {
  return valid.passwordStrong(value)
    ? undefined
    : 'От 6 до 64 символов, пароль должен содержать цифры, прописные и строчные буквы латиницей'
}

export const passwordSimpleValidator = (value: string) => {
  return valid.passwordSimple(value)
    ? undefined
    : 'От 6 до 64 символов, пароль должен содержать цифры и буквы латиницей'
}

export const inn = (value: string) =>
  valid.inn(value) ? undefined : 'Поле должно содержать корректный номер ИНН (10 или 12 цифр)'

export const phone = (value: string) =>
  valid.phone(value) ? undefined : 'Поле должно содержать корректный номер телефона'

export const phoneNotRequired = (value: string) =>
  value && !valid.phone(value) ? 'Поле должно содержать корректный номер телефона' : undefined

export const antiFraudPhone = (value: string) => {
  const fraudRegExpStrings = [
    /^(?!.*(\d)\1{6,})/, // в значении не более 6-ти идущих подряд цифр
    /^\d[3,4,8,9]\d{9}$/, // код региона начинается правильно
  ]
  // slice нужен, чтобы при попытке ввести лишние символы они не попадали в значение поля
  const phoneValue = value.slice(0, 11).replace(/[^\d]/g, '')

  for (let i = 0; i < fraudRegExpStrings.length; i += 1) {
    const regExp = new RegExp(fraudRegExpStrings[i])

    if (!regExp.test(phoneValue)) {
      return 'Поле должно содержать корректный номер телефона'
    }
  }

  return undefined
}

export const personnelNumber = (value: string) =>
  valid.number(value) && value.length === 8 ? undefined : 'Поле может быть только 8-ми значным и цифирным.'
