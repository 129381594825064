import qs from 'qs'
import { TResUser } from 'types/user'

import DEFAULT_AGREEMENT_TYPES from 'configs/agreementTypes'

import { Footer, Header } from 'components'
import { DATALAYER_CUSTOM_EVENT, DATALAYER_EVENT_REGISTRATION } from 'configs/dataLayer'
import { TFormConfig, TFormConfigFn } from 'containers/FormBuilder'
import { request } from 'utils/api'
import { setAuthData } from 'utils/auth'
import getFormFieldsByType from 'utils/getFormFieldsByType'
import { redirect, redirectPreRenderHook } from 'utils/redirect'
import { commonRegistrationActions } from 'utils/registration'
import { dataLayerHandler } from 'utils/sendDataLayer'
import { phone as phoneValidator, required } from 'utils/validators'

import CHANNEL_IDS from '../../channels'
import { dummyUser } from '../../consts'
import { phoneFieldProps } from '../../defaultFieldsProps'

const channelId = CHANNEL_IDS.PHN

const config = {
  oAuth: {
    withLogo: false,
  },
}

type TPartialFormConfig = Partial<Pick<TFormConfig, 'title' | 'withLogo'>>

const emailRegistration: TFormConfigFn<{ type: string }> = ({
  location: {
    query: { type },
  },
}) => {
  const { title = 'Регистрация', withLogo = true } = getFormFieldsByType<TPartialFormConfig>(config)(type)

  return {
    title,
    submitButtonText: 'Зарегистрироваться',
    header: <Header type="registration-by-phone" />,
    footer: <Footer type="dsAgree" />,
    withLogo,
    fields: [
      {
        ...phoneFieldProps,
        validators: [required, phoneValidator],
        fieldSuffix: 'regByPhone',
      },
    ],
    successMessage: 'На указанный вами телефон отправлено СМС со паролем для входа',
    postRender: redirectPreRenderHook,
    agreementTypesToLoad: DEFAULT_AGREEMENT_TYPES,
    defaultTruthForAgreementTypes: DEFAULT_AGREEMENT_TYPES,
    onRequest: (values) => {
      const reqParams = {
        location: `core-router/v2:register/${channelId}`,
        params: {
          data: {
            ...dummyUser,
            ...values,
            channelId,
            email: '',
          },
        },
      }

      return request(reqParams)
    },
    postHook: async (_, response, { history: { push }, location: { query } }, values) => {
      const { phone } = values

      if (response) {
        const nextAuthData = response as TResUser
        const { data } = response as TResUser
        nextAuthData.data = {
          ...data,
          phone,
        }

        dataLayerHandler({
          event: DATALAYER_CUSTOM_EVENT,
          eventCategory: DATALAYER_EVENT_REGISTRATION,
          eventAction: channelId,
          userId: data?.id,
        })

        setAuthData(nextAuthData)
        commonRegistrationActions()
        // не можем отправить в GTR, т.к. нет email

        const nextParams: { [key: string]: string } = query
        nextParams.phone = phone
        nextParams.form = 'phone'

        redirect({ push, url: `/profile-change?${qs.stringify(nextParams)}` })
      }
    },
  }
}

export default emailRegistration
